import React, { useRef, useState } from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'
import { ActionButton, ArrowButton } from 'components/inputs'


type AnnouncementProps = {
  className?: string
  title?: string
  text?: string
  maxLength?: number
}

const Announcement: React.FC<AnnouncementProps> = (props) => {
  const { className, title, text, maxLength } = props
  const [ isOpened, setIsOpened ] = useState<boolean>(false)

  const rootRef = useRef<HTMLDivElement>()

  return (
    <div
      className={cx(className, 'radius-16 bg-warrior px-20px py-12px mb-16px')}
      style={{ border: '1px solid #FBECDD' }}
      ref={rootRef}
    >
      <Text
        message={title}
        size="h16-20"
        color="schindler"
      />
      <Text
        className={cx('opacity-48 mt-4px', { 'overflow-ellipsis-3': !isOpened })} // TODO figure out why ellipsis is not working (see <RecordPage />)
        message={text}
        size="s13-r"
        color="schindler"
      />
      {
        (maxLength && text.length > maxLength) && (
          <ActionButton
            className="mt-6px justify-start"
            title={isOpened ? 'Скрыть' : 'Читать полностью'}
            color="lebowski"
            arrow={isOpened ? 'up' : 'down'}
            arrowSize={8}
            size="c13"
            onClick={() => {
              setIsOpened((prev) => !prev)
            }}
          />
        )
      }
    </div>
  )
}

export default Announcement