import _arrowArrowBoldDown_12 from "./12/arrow/arrow-bold-down_12.svg"
import _arrowArrowBoldRight_12 from "./12/arrow/arrow-bold-right_12.svg"
import _arrowArrowBoldUp_12 from "./12/arrow/arrow-bold-up_12.svg"
import _arrowArrowDown_12 from "./12/arrow/arrow-down_12.svg"
import _arrowArrowLeft_12 from "./12/arrow/arrow-left_12.svg"
import _arrowArrowRight_12 from "./12/arrow/arrow-right_12.svg"
import _arrowArrowUp_12 from "./12/arrow/arrow-up_12.svg"
import _actionAddDirectory_16 from "./16/action/add-directory_16.svg"
import _actionArchive_16 from "./16/action/archive_16.svg"
import _actionAttach_16 from "./16/action/attach_16.svg"
import _actionClose_16 from "./16/action/close_16.svg"
import _actionDublicate_16 from "./16/action/dublicate_16.svg"
import _actionExit_16 from "./16/action/exit_16.svg"
import _actionExport_16 from "./16/action/export_16.svg"
import _actionFavoriteAdd_16 from "./16/action/favorite-add_16.svg"
import _actionFavoriteAddActive_16 from "./16/action/favorite-add-active_16.svg"
import _actionFavorites_16 from "./16/action/favorites_16.svg"
import _actionFavoritesActive_16 from "./16/action/favorites-active_16.svg"
import _actionLink_16 from "./16/action/link_16.svg"
import _actionLoad_16 from "./16/action/load_16.svg"
import _actionLock_16 from "./16/action/lock_16.svg"
import _actionMove_16 from "./16/action/move_16.svg"
import _actionPin_16 from "./16/action/pin_16.svg"
import _actionPrint_16 from "./16/action/print_16.svg"
import _actionRestore_16 from "./16/action/restore_16.svg"
import _actionSave_16 from "./16/action/save_16.svg"
import _actionSelected_16 from "./16/action/selected_16.svg"
import _actionShare_16 from "./16/action/share_16.svg"
import _actionUnpin_16 from "./16/action/unpin_16.svg"
import _actionUpdate_16 from "./16/action/update_16.svg"
import _appealsPublic_16 from "./16/appeals/public_16.svg"
import _appealsRoads_16 from "./16/appeals/roads_16.svg"
import _arrowArrowBoldDown_16 from "./16/arrow/arrow-bold-down_16.svg"
import _arrowArrowBoldRight_16 from "./16/arrow/arrow-bold-right_16.svg"
import _arrowArrowBoldUp_16 from "./16/arrow/arrow-bold-up_16.svg"
import _arrowArrowDown_16 from "./16/arrow/arrow-down_16.svg"
import _arrowArrowLeft_16 from "./16/arrow/arrow-left_16.svg"
import _arrowArrowRight_16 from "./16/arrow/arrow-right_16.svg"
import _arrowArrowUp_16 from "./16/arrow/arrow-up_16.svg"
import _businessAchievement_16 from "./16/business/achievement_16.svg"
import _businessBusinessCase_16 from "./16/business/business-case_16.svg"
import _businessChart_16 from "./16/business/chart_16.svg"
import _businessChartAnalytics_16 from "./16/business/chart-analytics_16.svg"
import _businessConcept_16 from "./16/business/concept_16.svg"
import _businessDashboard_16 from "./16/business/dashboard_16.svg"
import _businessFinanceMoney_16 from "./16/business/finance-money_16.svg"
import _businessGrowth_16 from "./16/business/growth_16.svg"
import _businessMonitoring_16 from "./16/business/monitoring_16.svg"
import _businessProgress_16 from "./16/business/progress_16.svg"
import _businessRegression_16 from "./16/business/regression_16.svg"
import _businessSector_16 from "./16/business/sector_16.svg"
import _businessStatistics_16 from "./16/business/statistics_16.svg"
import _cadastralBlueprint_16 from "./16/cadastral/blueprint_16.svg"
import _cadastralMncplControl_16 from "./16/cadastral/mncpl-control_16.svg"
import _communicationAnswer_16 from "./16/communication/answer_16.svg"
import _communicationAnswerAll_16 from "./16/communication/answer-all_16.svg"
import _communicationAt_16 from "./16/communication/at_16.svg"
import _communicationGrid_16 from "./16/communication/grid_16.svg"
import _communicationLocalPhone_16 from "./16/communication/local-phone_16.svg"
import _communicationMail_16 from "./16/communication/mail_16.svg"
import _communicationMessage_16 from "./16/communication/message_16.svg"
import _communicationRedo_16 from "./16/communication/redo_16.svg"
import _communicationReply_16 from "./16/communication/reply_16.svg"
import _communicationSelection_16 from "./16/communication/selection_16.svg"
import _communicationSend_16 from "./16/communication/send_16.svg"
import _communicationTelephone_16 from "./16/communication/telephone_16.svg"
import _customGraph_16 from "./16/custom/graph_16.svg"
import _ecologyDanger_16 from "./16/ecology/danger_16.svg"
import _ecologyEcology_16 from "./16/ecology/ecology_16.svg"
import _ecologyEcopost_16 from "./16/ecology/ecopost_16.svg"
import _ecologyGreenings_16 from "./16/ecology/greenings_16.svg"
import _ecologyLawn_16 from "./16/ecology/lawn_16.svg"
import _ecologyRecycling_16 from "./16/ecology/recycling_16.svg"
import _ecologySeparate_16 from "./16/ecology/separate_16.svg"
import _ecologyTko_16 from "./16/ecology/tko_16.svg"
import _ecologyTree_16 from "./16/ecology/tree_16.svg"
import _ecologyWater_16 from "./16/ecology/water_16.svg"
import _ecologyWind_16 from "./16/ecology/wind_16.svg"
import _fileAgreement_16 from "./16/file/agreement_16.svg"
import _fileApplication_16 from "./16/file/application_16.svg"
import _fileAuditor_16 from "./16/file/auditor_16.svg"
import _fileErrand_16 from "./16/file/errand_16.svg"
import _fileExcelFile_16 from "./16/file/excel-file_16.svg"
import _fileFile_16 from "./16/file/file_16.svg"
import _fileFileDownload_16 from "./16/file/file-download_16.svg"
import _fileInsert_16 from "./16/file/insert_16.svg"
import _fileList_16 from "./16/file/list_16.svg"
import _fileNewFolder_16 from "./16/file/new-folder_16.svg"
import _fileOfficialFile_16 from "./16/file/official-file_16.svg"
import _fileOrder_16 from "./16/file/order_16.svg"
import _filePdfFile_16 from "./16/file/pdf-file_16.svg"
import _fileProfilactic_16 from "./16/file/profilactic_16.svg"
import _fileRegistry_16 from "./16/file/registry_16.svg"
import _fileSignatureFile_16 from "./16/file/signature-file_16.svg"
import _fileTemplate_16 from "./16/file/template_16.svg"
import _fileWordFile_16 from "./16/file/word-file_16.svg"
import _governmentAgricultural_16 from "./16/government/agricultural_16.svg"
import _governmentArt_16 from "./16/government/art_16.svg"
import _governmentCityClean_16 from "./16/government/city-clean_16.svg"
import _governmentConstruction_16 from "./16/government/construction_16.svg"
import _governmentCulture_16 from "./16/government/culture_16.svg"
import _governmentEducation_16 from "./16/government/education_16.svg"
import _governmentEmergency_16 from "./16/government/emergency_16.svg"
import _governmentFoodService_16 from "./16/government/food-service_16.svg"
import _governmentForeign_16 from "./16/government/foreign_16.svg"
import _governmentFuneral_16 from "./16/government/funeral_16.svg"
import _governmentGovernment_16 from "./16/government/government_16.svg"
import _governmentGroundWork_16 from "./16/government/ground-work_16.svg"
import _governmentLaw_16 from "./16/government/law_16.svg"
import _governmentMedicine_16 from "./16/government/medicine_16.svg"
import _governmentNew_16 from "./16/government/new_16.svg"
import _governmentPartnership_16 from "./16/government/partnership_16.svg"
import _governmentPolice_16 from "./16/government/police_16.svg"
import _governmentRussiaGovernment_16 from "./16/government/russia-government_16.svg"
import _governmentSecurity_16 from "./16/government/security_16.svg"
import _governmentSport_16 from "./16/government/sport_16.svg"
import _governmentVeterinary_16 from "./16/government/veterinary_16.svg"
import _governmentVoice_16 from "./16/government/voice_16.svg"
import _industryCanalization_16 from "./16/industry/canalization_16.svg"
import _industryColdWater_16 from "./16/industry/cold-water_16.svg"
import _industryDash_16 from "./16/industry/dash_16.svg"
import _industryElectricPower_16 from "./16/industry/electric-power_16.svg"
import _industryEnergySupply_16 from "./16/industry/energy-supply_16.svg"
import _industryExtinguisher_16 from "./16/industry/extinguisher_16.svg"
import _industryGasSupply_16 from "./16/industry/gas-supply_16.svg"
import _industryHcs_16 from "./16/industry/hcs_16.svg"
import _industryHeat_16 from "./16/industry/heat_16.svg"
import _industryHeatWater_16 from "./16/industry/heat-water_16.svg"
import _industryHotWater_16 from "./16/industry/hot-water_16.svg"
import _industryHydrant_16 from "./16/industry/hydrant_16.svg"
import _industryResourcesSupply_16 from "./16/industry/resources-supply_16.svg"
import _itBattery_16 from "./16/IT/battery_16.svg"
import _itCameraCctv_16 from "./16/IT/camera-cctv_16.svg"
import _itCloudStorage_16 from "./16/IT/cloud-storage_16.svg"
import _itDataSystem_16 from "./16/IT/data-system_16.svg"
import _itHumanCapital_16 from "./16/IT/human-capital_16.svg"
import _itPhoto_16 from "./16/IT/photo_16.svg"
import _itSocialNet_16 from "./16/IT/social-net_16.svg"
import _itTv_16 from "./16/IT/tv_16.svg"
import _itVideoControl_16 from "./16/IT/video-control_16.svg"
import _mainAdd_16 from "./16/main/add_16.svg"
import _mainAvatar_16 from "./16/main/avatar_16.svg"
import _mainBlock_16 from "./16/main/block_16.svg"
import _mainDelete_16 from "./16/main/delete_16.svg"
import _mainEdit_16 from "./16/main/edit_16.svg"
import _mainEditPicture_16 from "./16/main/edit-picture_16.svg"
import _mainEye_16 from "./16/main/eye_16.svg"
import _mainHelp_16 from "./16/main/help_16.svg"
import _mainHome_16 from "./16/main/home_16.svg"
import _mainImage_16 from "./16/main/image_16.svg"
import _mainInfo_16 from "./16/main/info_16.svg"
import _mainNotifications_16 from "./16/main/notifications_16.svg"
import _mainNotificationsActive_16 from "./16/main/notifications-active_16.svg"
import _mainRemove_16 from "./16/main/remove_16.svg"
import _mainSearch_16 from "./16/main/search_16.svg"
import _mainSettings_16 from "./16/main/settings_16.svg"
import _mainWarning_16 from "./16/main/warning_16.svg"
import _mapGeopoint_16 from "./16/map/geopoint_16.svg"
import _mapGeoposition_16 from "./16/map/geoposition_16.svg"
import _mapLayers_16 from "./16/map/layers_16.svg"
import _mapLayersActive_16 from "./16/map/layers-active_16.svg"
import _mapLocation_16 from "./16/map/location_16.svg"
import _mapRoute_16 from "./16/map/route_16.svg"
import _menuBorderShift_16 from "./16/menu/border-shift_16.svg"
import _menuClean_16 from "./16/menu/clean_16.svg"
import _menuDecrease_16 from "./16/menu/decrease_16.svg"
import _menuExitFullscreen_16 from "./16/menu/exit-fullscreen_16.svg"
import _menuExpand_16 from "./16/menu/expand_16.svg"
import _menuFilter_16 from "./16/menu/filter_16.svg"
import _menuFullscreen_16 from "./16/menu/fullscreen_16.svg"
import _menuIconView_16 from "./16/menu/icon-view_16.svg"
import _menuIncrease_16 from "./16/menu/increase_16.svg"
import _menuMenu_16 from "./16/menu/menu_16.svg"
import _menuMenuContext_16 from "./16/menu/menu-context_16.svg"
import _menuOther_16 from "./16/menu/other_16.svg"
import _menuParameters_16 from "./16/menu/parameters_16.svg"
import _menuSort_16 from "./16/menu/sort_16.svg"
import _menuSwitch_16 from "./16/menu/switch_16.svg"
import _menuTable_sort_16 from "./16/menu/table_sort_16.svg"
import _otherCouch_16 from "./16/other/couch_16.svg"
import _otherEtc_16 from "./16/other/etc_16.svg"
import _otherHotMeal_16 from "./16/other/hot-meal_16.svg"
import _otherKnowledge_16 from "./16/other/knowledge_16.svg"
import _otherSnow_16 from "./16/other/snow_16.svg"
import _otherVaccine_16 from "./16/other/vaccine_16.svg"
import _otherVirus_16 from "./16/other/virus_16.svg"
import _placesBuildings_16 from "./16/places/buildings_16.svg"
import _placesChildPlayground_16 from "./16/places/child-playground_16.svg"
import _placesMayor_16 from "./16/places/mayor_16.svg"
import _placesPark_16 from "./16/places/park_16.svg"
import _placesPlant_16 from "./16/places/plant_16.svg"
import _placesPublicSpace_16 from "./16/places/public-space_16.svg"
import _placesThermalStation_16 from "./16/places/thermal-station_16.svg"
import _playerForward_16 from "./16/player/forward_16.svg"
import _playerMicrophone_16 from "./16/player/microphone_16.svg"
import _playerMusic_16 from "./16/player/music_16.svg"
import _playerPauseSolid_16 from "./16/player/pause-solid_16.svg"
import _playerPlay_16 from "./16/player/play_16.svg"
import _playerPlaySolid_16 from "./16/player/play-solid_16.svg"
import _playerRewind_16 from "./16/player/rewind_16.svg"
import _playerSoundOn_16 from "./16/player/sound-on_16.svg"
import _ratingDislike_16 from "./16/rating/dislike_16.svg"
import _ratingDislikeActive_16 from "./16/rating/dislike-active_16.svg"
import _ratingDowngrade_16 from "./16/rating/downgrade_16.svg"
import _ratingLike_16 from "./16/rating/like_16.svg"
import _ratingLikeActive_16 from "./16/rating/like-active_16.svg"
import _ratingRaising_16 from "./16/rating/raising_16.svg"
import _ratingStar_16 from "./16/rating/star_16.svg"
import _ratingStarActive_16 from "./16/rating/star-active_16.svg"
import _retailAlc_16 from "./16/retail/alc_16.svg"
import _retailBread_16 from "./16/retail/bread_16.svg"
import _retailCard_16 from "./16/retail/card_16.svg"
import _retailCategory_16 from "./16/retail/category_16.svg"
import _retailComsumer_16 from "./16/retail/comsumer_16.svg"
import _retailPaymentHcs_16 from "./16/retail/payment-hcs_16.svg"
import _retailPaymentWallet_16 from "./16/retail/payment-wallet_16.svg"
import _retailPrepareRetail_16 from "./16/retail/prepare-retail_16.svg"
import _retailProduct_16 from "./16/retail/product_16.svg"
import _retailPurchases_16 from "./16/retail/purchases_16.svg"
import _retailServices_16 from "./16/retail/services_16.svg"
import _retailShoppingBag_16 from "./16/retail/shopping-bag_16.svg"
import _retailSupplier_16 from "./16/retail/supplier_16.svg"
import _retailSupplierSearch_16 from "./16/retail/supplier-search_16.svg"
import _retailTradeObjects_16 from "./16/retail/trade-objects_16.svg"
import _textAlignCenter_16 from "./16/text/align-center_16.svg"
import _textAlignLeft_16 from "./16/text/align-left_16.svg"
import _textAlignRight_16 from "./16/text/align-right_16.svg"
import _textBold_16 from "./16/text/bold_16.svg"
import _textBulletList_16 from "./16/text/bullet-list_16.svg"
import _textCodePaste_16 from "./16/text/code-paste_16.svg"
import _textCopy_16 from "./16/text/copy_16.svg"
import _textCross_16 from "./16/text/cross_16.svg"
import _textEraser_16 from "./16/text/eraser_16.svg"
import _textItalic_16 from "./16/text/italic_16.svg"
import _textLine_16 from "./16/text/line_16.svg"
import _textMedium_16 from "./16/text/medium_16.svg"
import _textNumberList_16 from "./16/text/number-list_16.svg"
import _textQuote_16 from "./16/text/quote_16.svg"
import _textType_16 from "./16/text/type_16.svg"
import _textUnderline_16 from "./16/text/underline_16.svg"
import _textWidthAlignment_16 from "./16/text/width-alignment_16.svg"
import _timeCalendar_16 from "./16/time/calendar_16.svg"
import _timeClock_16 from "./16/time/clock_16.svg"
import _timeFinish_16 from "./16/time/finish_16.svg"
import _timeInProgress_16 from "./16/time/in-progress_16.svg"
import _timeLoader_16 from "./16/time/loader_16.svg"
import _timeStart_16 from "./16/time/start_16.svg"
import _timeTimer_16 from "./16/time/timer_16.svg"
import _timeTimerFinish_16 from "./16/time/timer-finish_16.svg"
import _timeTimerStart_16 from "./16/time/timer-start_16.svg"
import _timeWaiting_16 from "./16/time/waiting_16.svg"
import _transportAirport_16 from "./16/transport/airport_16.svg"
import _transportAvailableEnvironment_16 from "./16/transport/available-environment_16.svg"
import _transportBus_16 from "./16/transport/bus_16.svg"
import _transportBusStop_16 from "./16/transport/bus-stop_16.svg"
import _transportCar_16 from "./16/transport/car_16.svg"
import _transportConductor_16 from "./16/transport/conductor_16.svg"
import _transportCrosswalk_16 from "./16/transport/crosswalk_16.svg"
import _transportDescent_16 from "./16/transport/descent_16.svg"
import _transportDriver_16 from "./16/transport/driver_16.svg"
import _transportEmergencyStop_16 from "./16/transport/emergency-stop_16.svg"
import _transportMinibus_16 from "./16/transport/minibus_16.svg"
import _transportPit_16 from "./16/transport/pit_16.svg"
import _transportPublicTransportTrolley_16 from "./16/transport/public-transport-trolley_16.svg"
import _transportRoadBlock_16 from "./16/transport/road-block_16.svg"
import _transportRoadControl_16 from "./16/transport/road-control_16.svg"
import _transportRoadSing_16 from "./16/transport/road-sing_16.svg"
import _transportRoadWork_16 from "./16/transport/road-work_16.svg"
import _transportTechnic_16 from "./16/transport/technic_16.svg"
import _transportTrafficLights_16 from "./16/transport/traffic-lights_16.svg"
import _transportTrafficLightsWithIndicator_16 from "./16/transport/traffic-lights-with-indicator_16.svg"
import _transportTram_16 from "./16/transport/tram_16.svg"
import _transportWalker_16 from "./16/transport/walker_16.svg"
import _workActiveCitizen_16 from "./16/work/active-citizen_16.svg"
import _workAdvance_16 from "./16/work/advance_16.svg"
import _workButton_16 from "./16/work/button_16.svg"
import _workCompetenceResume_16 from "./16/work/competence-resume_16.svg"
import _workComplaint_16 from "./16/work/complaint_16.svg"
import _workCrutches_16 from "./16/work/crutches_16.svg"
import _workEmployee_16 from "./16/work/employee_16.svg"
import _workEmployment_16 from "./16/work/employment_16.svg"
import _workInvalid_16 from "./16/work/invalid_16.svg"
import _workNoHearing_16 from "./16/work/no-hearing_16.svg"
import _workNoVision_16 from "./16/work/no-vision_16.svg"
import _workSupport_16 from "./16/work/support_16.svg"
import _workTactileSurface_16 from "./16/work/tactile-surface_16.svg"
import _workTeamPeople_16 from "./16/work/team-people_16.svg"
import _workWorker_16 from "./16/work/worker_16.svg"
import _actionAddDirectory_20 from "./20/action/add-directory_20.svg"
import _actionArchive_20 from "./20/action/archive_20.svg"
import _actionAttach_20 from "./20/action/attach_20.svg"
import _actionClose_20 from "./20/action/close_20.svg"
import _actionExit_20 from "./20/action/exit_20.svg"
import _actionExport_20 from "./20/action/export_20.svg"
import _actionFavoriteAdd_20 from "./20/action/favorite-add_20.svg"
import _actionFavoriteAddActive_20 from "./20/action/favorite-add-active_20.svg"
import _actionFavorites_20 from "./20/action/favorites_20.svg"
import _actionFavoritesActive_20 from "./20/action/favorites-active_20.svg"
import _actionImageEdit_20 from "./20/action/image-edit_20.svg"
import _actionLink_20 from "./20/action/link_20.svg"
import _actionLoad_20 from "./20/action/load_20.svg"
import _actionMove_20 from "./20/action/move_20.svg"
import _actionPin_20 from "./20/action/pin_20.svg"
import _actionPrint_20 from "./20/action/print_20.svg"
import _actionRestore_20 from "./20/action/restore_20.svg"
import _actionSave_20 from "./20/action/save_20.svg"
import _actionSelected_20 from "./20/action/selected_20.svg"
import _actionShare_20 from "./20/action/share_20.svg"
import _actionUnpin_20 from "./20/action/unpin_20.svg"
import _actionUpdate_20 from "./20/action/update_20.svg"
import _arrowArrowBoldDown_20 from "./20/arrow/arrow-bold-down_20.svg"
import _arrowArrowBoldRight_20 from "./20/arrow/arrow-bold-right_20.svg"
import _arrowArrowBoldUp_20 from "./20/arrow/arrow-bold-up_20.svg"
import _arrowArrowDown_20 from "./20/arrow/arrow-down_20.svg"
import _arrowArrowLeft_20 from "./20/arrow/arrow-left_20.svg"
import _arrowArrowRight_20 from "./20/arrow/arrow-right_20.svg"
import _arrowArrowUp_20 from "./20/arrow/arrow-up_20.svg"
import _businessAchievement_20 from "./20/business/achievement_20.svg"
import _businessChartAnalytics_20 from "./20/business/chart-analytics_20.svg"
import _businessMonitoring_20 from "./20/business/monitoring_20.svg"
import _businessSector_20 from "./20/business/sector_20.svg"
import _cadastralMncplControl_20 from "./20/cadastral/mncpl-control_20.svg"
import _communicationAnswer_20 from "./20/communication/answer_20.svg"
import _communicationMail_20 from "./20/communication/mail_20.svg"
import _communicationMessage_20 from "./20/communication/message_20.svg"
import _communicationReply_20 from "./20/communication/reply_20.svg"
import _communicationSelection_20 from "./20/communication/selection_20.svg"
import _communicationSend_20 from "./20/communication/send_20.svg"
import _communicationTelephone_20 from "./20/communication/telephone_20.svg"
import _ecologyTko_20 from "./20/ecology/tko_20.svg"
import _fileAgreement_20 from "./20/file/agreement_20.svg"
import _fileErrand_20 from "./20/file/errand_20.svg"
import _fileFile_20 from "./20/file/file_20.svg"
import _fileFileDownload_20 from "./20/file/file-download_20.svg"
import _fileInsert_20 from "./20/file/insert_20.svg"
import _fileNewFolder_20 from "./20/file/new-folder_20.svg"
import _filePdfFileDownload_20 from "./20/file/pdf-file-download_20.svg"
import _fileProfilactic_20 from "./20/file/profilactic_20.svg"
import _governmentMedicine_20 from "./20/government/medicine_20.svg"
import _governmentNew_20 from "./20/government/new_20.svg"
import _governmentPartnership_20 from "./20/government/partnership_20.svg"
import _governmentPolice_20 from "./20/government/police_20.svg"
import _governmentSecurity_20 from "./20/government/security_20.svg"
import _governmentVoice_20 from "./20/government/voice_20.svg"
import _governmentVote_20 from "./20/government/vote_20.svg"
import _industryCanalization_20 from "./20/industry/canalization_20.svg"
import _industryCentralPanel_20 from "./20/industry/central-panel_20.svg"
import _industryDash_20 from "./20/industry/dash_20.svg"
import _itVideoControl_20 from "./20/IT/video-control_20.svg"
import _mainAdd_20 from "./20/main/add_20.svg"
import _mainAvatar_20 from "./20/main/avatar_20.svg"
import _mainBlock_20 from "./20/main/block_20.svg"
import _mainDelete_20 from "./20/main/delete_20.svg"
import _mainEdit_20 from "./20/main/edit_20.svg"
import _mainEditPicture_20 from "./20/main/edit-picture_20.svg"
import _mainEye_20 from "./20/main/eye_20.svg"
import _mainHelp_20 from "./20/main/help_20.svg"
import _mainHome_20 from "./20/main/home_20.svg"
import _mainImage_20 from "./20/main/image_20.svg"
import _mainInfo_20 from "./20/main/info_20.svg"
import _mainNotifications_20 from "./20/main/notifications_20.svg"
import _mainRemove_20 from "./20/main/remove_20.svg"
import _mainSearch_20 from "./20/main/search_20.svg"
import _mainSettings_20 from "./20/main/settings_20.svg"
import _mainWarning_20 from "./20/main/warning_20.svg"
import _mapGeopoint_20 from "./20/map/geopoint_20.svg"
import _mapLayers_20 from "./20/map/layers_20.svg"
import _mapLayersActive_20 from "./20/map/layers-active_20.svg"
import _mapLocation_20 from "./20/map/location_20.svg"
import _menuBorderShift_20 from "./20/menu/border-shift_20.svg"
import _menuClean_20 from "./20/menu/clean_20.svg"
import _menuExitFullscreen_20 from "./20/menu/exit-fullscreen_20.svg"
import _menuExpand_20 from "./20/menu/expand_20.svg"
import _menuFilter_20 from "./20/menu/filter_20.svg"
import _menuFullscreen_20 from "./20/menu/fullscreen_20.svg"
import _menuIconView_20 from "./20/menu/icon-view_20.svg"
import _menuMenu_20 from "./20/menu/menu_20.svg"
import _menuMenuContext_20 from "./20/menu/menu-context_20.svg"
import _menuOther_20 from "./20/menu/other_20.svg"
import _menuParameters_20 from "./20/menu/parameters_20.svg"
import _menuSort_20 from "./20/menu/sort_20.svg"
import _otherEtc_20 from "./20/other/etc_20.svg"
import _otherMedal_20 from "./20/other/medal_20.svg"
import _placesBuildings_20 from "./20/places/buildings_20.svg"
import _placesMayor_20 from "./20/places/mayor_20.svg"
import _playerForward_20 from "./20/player/forward_20.svg"
import _playerMicrophone_20 from "./20/player/microphone_20.svg"
import _playerMusic_20 from "./20/player/music_20.svg"
import _playerPauseSolid_20 from "./20/player/pause-solid_20.svg"
import _playerPlay_20 from "./20/player/play_20.svg"
import _playerPlaySolid_20 from "./20/player/play-solid_20.svg"
import _playerRewind_20 from "./20/player/rewind_20.svg"
import _ratingDislike_20 from "./20/rating/dislike_20.svg"
import _ratingLike_20 from "./20/rating/like_20.svg"
import _ratingStar_20 from "./20/rating/star_20.svg"
import _ratingStarActive_20 from "./20/rating/star-active_20.svg"
import _retailShoppingBag_20 from "./20/retail/shopping-bag_20.svg"
import _textAlignCenter_20 from "./20/text/align-center_20.svg"
import _textAlignLeft_20 from "./20/text/align-left_20.svg"
import _textAlignRight_20 from "./20/text/align-right_20.svg"
import _textBold_20 from "./20/text/bold_20.svg"
import _textCopy_20 from "./20/text/copy_20.svg"
import _textItalic_20 from "./20/text/italic_20.svg"
import _textMedium_20 from "./20/text/medium_20.svg"
import _textType_20 from "./20/text/type_20.svg"
import _textWidthAlignment_20 from "./20/text/width-alignment_20.svg"
import _timeCalendar_20 from "./20/time/calendar_20.svg"
import _timeClock_20 from "./20/time/clock_20.svg"
import _timeInProgress_20 from "./20/time/in-progress_20.svg"
import _timeLoader_20 from "./20/time/loader_20.svg"
import _timeTimer_20 from "./20/time/timer_20.svg"
import _timeWaiting_20 from "./20/time/waiting_20.svg"
import _transportBus_20 from "./20/transport/bus_20.svg"
import _transportEmergencyStop_20 from "./20/transport/emergency-stop_20.svg"
import _transportMinibus_20 from "./20/transport/minibus_20.svg"
import _transportPublicTransportTrolley_20 from "./20/transport/public-transport-trolley_20.svg"
import _transportRoadControl_20 from "./20/transport/road-control_20.svg"
import _transportRoutes_20 from "./20/transport/routes_20.svg"
import _transportTechnic_20 from "./20/transport/technic_20.svg"
import _transportTram_20 from "./20/transport/tram_20.svg"
import _workInvalid_20 from "./20/work/invalid_20.svg"
import _workSupport_20 from "./20/work/support_20.svg"
import _arrowArrowBoldDown_24 from "./24/arrow/arrow-bold-down_24.svg"
import _arrowArrowBoldRight_24 from "./24/arrow/arrow-bold-right_24.svg"
import _arrowArrowBoldUp_24 from "./24/arrow/arrow-bold-up_24.svg"
import _actionAddDirectory_32 from "./32/action/add-directory_32.svg"
import _actionArchive_32 from "./32/action/archive_32.svg"
import _actionAttach_32 from "./32/action/attach_32.svg"
import _actionClose_32 from "./32/action/close_32.svg"
import _actionDash_32 from "./32/action/dash_32.svg"
import _actionExit_32 from "./32/action/exit_32.svg"
import _actionExport_32 from "./32/action/export_32.svg"
import _actionFavoriteAdd_32 from "./32/action/favorite-add_32.svg"
import _actionFavoriteAddActive_32 from "./32/action/favorite-add-active_32.svg"
import _actionLink_32 from "./32/action/link_32.svg"
import _actionLoad_32 from "./32/action/load_32.svg"
import _actionMove_32 from "./32/action/move_32.svg"
import _actionRestore_32 from "./32/action/restore_32.svg"
import _actionSelected_32 from "./32/action/selected_32.svg"
import _actionUpdate_32 from "./32/action/update_32.svg"
import _arrowArrowBoldDown_32 from "./32/arrow/arrow-bold-down_32.svg"
import _arrowArrowBoldRight_32 from "./32/arrow/arrow-bold-right_32.svg"
import _arrowArrowBoldUp_32 from "./32/arrow/arrow-bold-up_32.svg"
import _arrowArrowDown_32 from "./32/arrow/arrow-down_32.svg"
import _arrowArrowLeft_32 from "./32/arrow/arrow-left_32.svg"
import _arrowArrowRight_32 from "./32/arrow/arrow-right_32.svg"
import _arrowArrowUp_32 from "./32/arrow/arrow-up_32.svg"
import _businessAchievement_32 from "./32/business/achievement_32.svg"
import _businessAim_32 from "./32/business/aim_32.svg"
import _businessBusinessCase_32 from "./32/business/business-case_32.svg"
import _businessBusinessSupport_32 from "./32/business/business-support_32.svg"
import _businessComplex_32 from "./32/business/complex_32.svg"
import _businessConcept_32 from "./32/business/concept_32.svg"
import _businessConcessions_32 from "./32/business/concessions_32.svg"
import _businessFinanceMoney_32 from "./32/business/finance-money_32.svg"
import _businessGrowth_32 from "./32/business/growth_32.svg"
import _businessInvestment_32 from "./32/business/investment_32.svg"
import _businessMarketShare_32 from "./32/business/market-share_32.svg"
import _businessMonitoring_32 from "./32/business/monitoring_32.svg"
import _businessOpportunities_32 from "./32/business/opportunities_32.svg"
import _businessProblemQuestion_32 from "./32/business/problem-question_32.svg"
import _businessQualityEffects_32 from "./32/business/quality-effects_32.svg"
import _businessQuantityEffects_32 from "./32/business/quantity-effects_32.svg"
import _businessSell_32 from "./32/business/sell_32.svg"
import _businessSteps_32 from "./32/business/steps_32.svg"
import _businessSwotMenaces_32 from "./32/business/swot-menaces_32.svg"
import _businessSwotStrong_32 from "./32/business/swot-strong_32.svg"
import _businessSwotWeak_32 from "./32/business/swot-weak_32.svg"
import _cadastralArea_32 from "./32/cadastral/area_32.svg"
import _cadastralCleaningTerritory_32 from "./32/cadastral/cleaning-territory_32.svg"
import _cadastralLandControl_32 from "./32/cadastral/land-control_32.svg"
import _cadastralLandLocateBuildings_32 from "./32/cadastral/land-locate-buildings_32.svg"
import _cadastralPlan_32 from "./32/cadastral/plan_32.svg"
import _cadastralSnowTerritory_32 from "./32/cadastral/snow-territory_32.svg"
import _cadastralTopography_32 from "./32/cadastral/topography_32.svg"
import _communicationAnswer_32 from "./32/communication/answer_32.svg"
import _communicationContacts_32 from "./32/communication/contacts_32.svg"
import _communicationEmail_32 from "./32/communication/email_32.svg"
import _communicationFriendlyPost_32 from "./32/communication/friendly-post_32.svg"
import _communicationMail_32 from "./32/communication/mail_32.svg"
import _communicationMessage_32 from "./32/communication/message_32.svg"
import _communicationReply_32 from "./32/communication/reply_32.svg"
import _communicationSelection_32 from "./32/communication/selection_32.svg"
import _communicationSend_32 from "./32/communication/send_32.svg"
import _communicationTelephone_32 from "./32/communication/telephone_32.svg"
import _ecologyDanger_32 from "./32/ecology/danger_32.svg"
import _ecologyEcoMonitoring_32 from "./32/ecology/eco-monitoring_32.svg"
import _ecologyEcology_32 from "./32/ecology/ecology_32.svg"
import _ecologyEcopost_32 from "./32/ecology/ecopost_32.svg"
import _ecologyGreenings_32 from "./32/ecology/greenings_32.svg"
import _ecologyRecycling_32 from "./32/ecology/recycling_32.svg"
import _ecologySeparate_32 from "./32/ecology/separate_32.svg"
import _ecologyTko_32 from "./32/ecology/tko_32.svg"
import _ecologyTree_32 from "./32/ecology/tree_32.svg"
import _ecologyWater_32 from "./32/ecology/water_32.svg"
import _ecologyWeatherMonitoring_32 from "./32/ecology/weather-monitoring_32.svg"
import _fileAgreement_32 from "./32/file/agreement_32.svg"
import _fileAuditor_32 from "./32/file/auditor_32.svg"
import _fileErrand_32 from "./32/file/errand_32.svg"
import _fileExcelFile_32 from "./32/file/excel-file_32.svg"
import _fileFile_32 from "./32/file/file_32.svg"
import _fileFileDownload_32 from "./32/file/file-download_32.svg"
import _fileInsert_32 from "./32/file/insert_32.svg"
import _fileList_32 from "./32/file/list_32.svg"
import _fileNewFolder_32 from "./32/file/new-folder_32.svg"
import _fileOfficialFile_32 from "./32/file/official-file_32.svg"
import _fileOrder_32 from "./32/file/order_32.svg"
import _filePdfFile_32 from "./32/file/pdf-file_32.svg"
import _fileResults_32 from "./32/file/results_32.svg"
import _fileTemplate_32 from "./32/file/template_32.svg"
import _fileWordFile_32 from "./32/file/word-file_32.svg"
import _governmentAgricultural_32 from "./32/government/agricultural_32.svg"
import _governmentArt_32 from "./32/government/art_32.svg"
import _governmentCity_clean_32 from "./32/government/city_clean_32.svg"
import _governmentConstruction_32 from "./32/government/construction_32.svg"
import _governmentCulture_32 from "./32/government/culture_32.svg"
import _governmentEducation_32 from "./32/government/education_32.svg"
import _governmentEmergency_32 from "./32/government/emergency_32.svg"
import _governmentEmergency_56 from "./32/government/emergency_56.svg"
import _governmentFoodService_32 from "./32/government/food-service_32.svg"
import _governmentForeign_32 from "./32/government/foreign_32.svg"
import _governmentFuneral_32 from "./32/government/funeral_32.svg"
import _governmentJudgment_32 from "./32/government/judgment_32.svg"
import _governmentMedicine_32 from "./32/government/medicine_32.svg"
import _governmentNew_32 from "./32/government/new_32.svg"
import _governmentPartnership_32 from "./32/government/partnership_32.svg"
import _governmentPolice_32 from "./32/government/police_32.svg"
import _governmentRestoration_32 from "./32/government/restoration_32.svg"
import _governmentRussiaGovernment_32 from "./32/government/russia-government_32.svg"
import _governmentSecurity_32 from "./32/government/security_32.svg"
import _governmentSport_32 from "./32/government/sport_32.svg"
import _governmentTourism_32 from "./32/government/tourism_32.svg"
import _governmentVeterinary_32 from "./32/government/veterinary_32.svg"
import _governmentVoice_32 from "./32/government/voice_32.svg"
import _governmentVote_32 from "./32/government/vote_32.svg"
import _industryCableSystem_32 from "./32/industry/cable-system_32.svg"
import _industryCanalization_32 from "./32/industry/canalization_32.svg"
import _industryColdWater_32 from "./32/industry/cold-water_32.svg"
import _industryConditioner_32 from "./32/industry/conditioner_32.svg"
import _industryDgh_32 from "./32/industry/dgh_32.svg"
import _industryElectricPower_32 from "./32/industry/electric-power_32.svg"
import _industryEnergySupply_32 from "./32/industry/energy-supply_32.svg"
import _industryEquipmentSettings_32 from "./32/industry/equipment-settings_32.svg"
import _industryGasSupply_32 from "./32/industry/gas-supply_32.svg"
import _industryHcs_32 from "./32/industry/hcs_32.svg"
import _industryHeat_32 from "./32/industry/heat_32.svg"
import _industryHeatWater_32 from "./32/industry/heat-water_32.svg"
import _industryHotWater_32 from "./32/industry/hot-water_32.svg"
import _industryLightSystem_32 from "./32/industry/light-system_32.svg"
import _industryNetworkSystem_32 from "./32/industry/network-system_32.svg"
import _industryResourcesSupply_32 from "./32/industry/resources-supply_32.svg"
import _industryVentilation_32 from "./32/industry/ventilation_32.svg"
import _itApp_32 from "./32/IT/app_32.svg"
import _itBigData_32 from "./32/IT/big-data_32.svg"
import _itCloudStorage_32 from "./32/IT/cloud-storage_32.svg"
import _itDataSystem_32 from "./32/IT/data-system_32.svg"
import _itDigitalServices_32 from "./32/IT/digital-services_32.svg"
import _itDigitalization_32 from "./32/IT/digitalization_32.svg"
import _itHumanCapital_32 from "./32/IT/human-capital_32.svg"
import _itIntegration_32 from "./32/IT/integration_32.svg"
import _itIntegrationPlatform_32 from "./32/IT/integration-platform_32.svg"
import _itItSolutions_32 from "./32/IT/it-solutions_32.svg"
import _itPersonalComputer_32 from "./32/IT/personal-computer_32.svg"
import _itSmartCity_32 from "./32/IT/smart-city_32.svg"
import _itSocialNet_32 from "./32/IT/social-net_32.svg"
import _itSystemSettings_32 from "./32/IT/system-settings_32.svg"
import _itTv_32 from "./32/IT/tv_32.svg"
import _itVideoControl_32 from "./32/IT/video-control_32.svg"
import _mainAdd_32 from "./32/main/add_32.svg"
import _mainAvatar_32 from "./32/main/avatar_32.svg"
import _mainBlock_32 from "./32/main/block_32.svg"
import _mainDelete_32 from "./32/main/delete_32.svg"
import _mainEdit_32 from "./32/main/edit_32.svg"
import _mainEye_32 from "./32/main/eye_32.svg"
import _mainHelp_32 from "./32/main/help_32.svg"
import _mainImage_32 from "./32/main/image_32.svg"
import _mainInfo_32 from "./32/main/info_32.svg"
import _mainNotifications_32 from "./32/main/notifications_32.svg"
import _mainRemove_32 from "./32/main/remove_32.svg"
import _mainSearch_32 from "./32/main/search_32.svg"
import _mainSettings_32 from "./32/main/settings_32.svg"
import _mainWarning_32 from "./32/main/warning_32.svg"
import _mapGeolocationMap_32 from "./32/map/geolocation-map_32.svg"
import _mapGeopoint_32 from "./32/map/geopoint_32.svg"
import _mapLayers_32 from "./32/map/layers_32.svg"
import _mapLayersActive_32 from "./32/map/layers-active_32.svg"
import _mapLocation_32 from "./32/map/location_32.svg"
import _mapRoute_32 from "./32/map/route_32.svg"
import _menuExitFullscreen_32 from "./32/menu/exit-fullscreen_32.svg"
import _menuExpand_32 from "./32/menu/expand_32.svg"
import _menuFilter_32 from "./32/menu/filter_32.svg"
import _menuFullscreen_32 from "./32/menu/fullscreen_32.svg"
import _menuMenu_32 from "./32/menu/menu_32.svg"
import _menuMenuContext_32 from "./32/menu/menu-context_32.svg"
import _menuOther_32 from "./32/menu/other_32.svg"
import _menuParameters_32 from "./32/menu/parameters_32.svg"
import _menuSort_32 from "./32/menu/sort_32.svg"
import _otherDedMoroz_32 from "./32/other/ded-moroz_32.svg"
import _otherEtc_32 from "./32/other/etc_32.svg"
import _otherGift_32 from "./32/other/gift_32.svg"
import _otherHotMeal_32 from "./32/other/hot-meal_32.svg"
import _otherVaccine_32 from "./32/other/vaccine_32.svg"
import _otherVirus_32 from "./32/other/virus_32.svg"
import _otherWindow_32 from "./32/other/window_32.svg"
import _placesAttractions_32 from "./32/places/attractions_32.svg"
import _placesBuildings_32 from "./32/places/buildings_32.svg"
import _placesChildPlayground_32 from "./32/places/child-playground_32.svg"
import _placesChildrensCamp_32 from "./32/places/childrens-camp_32.svg"
import _placesCultureObjects_32 from "./32/places/culture-objects_32.svg"
import _placesHospital_32 from "./32/places/hospital_32.svg"
import _placesInfrastructure_32 from "./32/places/infrastructure_32.svg"
import _placesInstitution_32 from "./32/places/institution_32.svg"
import _placesLeanSmartCity_32 from "./32/places/lean-smart-city_32.svg"
import _placesManaging_32 from "./32/places/managing_32.svg"
import _placesMayor_32 from "./32/places/mayor_32.svg"
import _placesMedicalPoint_32 from "./32/places/medical-point_32.svg"
import _placesMountains_32 from "./32/places/mountains_32.svg"
import _placesPark_32 from "./32/places/park_32.svg"
import _placesPlant_32 from "./32/places/plant_32.svg"
import _placesPolice_32 from "./32/places/police_32.svg"
import _placesPublicSpace_32 from "./32/places/public-space_32.svg"
import _placesRestPlaces_32 from "./32/places/rest-places_32.svg"
import _placesSportObjects_32 from "./32/places/sport-objects_32.svg"
import _placesThermalStation_32 from "./32/places/thermal-station_32.svg"
import _placesTradeModule_32 from "./32/places/trade-module_32.svg"
import _placesVoiting_32 from "./32/places/voiting_32.svg"
import _playerForward_32 from "./32/player/forward_32.svg"
import _playerMicrophone_32 from "./32/player/microphone_32.svg"
import _playerMusic_32 from "./32/player/music_32.svg"
import _playerPauseSolid_32 from "./32/player/pause-solid_32.svg"
import _playerPlay_32 from "./32/player/play_32.svg"
import _playerPlaySolid_32 from "./32/player/play-solid_32.svg"
import _playerRewind_32 from "./32/player/rewind_32.svg"
import _playerVideo_32 from "./32/player/video_32.svg"
import _ratingDislike_32 from "./32/rating/dislike_32.svg"
import _ratingLike_32 from "./32/rating/like_32.svg"
import _ratingSmileBadly_32 from "./32/rating/smile-badly_32.svg"
import _ratingSmileGreat_32 from "./32/rating/smile-great_32.svg"
import _ratingSmileNeutral_32 from "./32/rating/smile-neutral_32.svg"
import _ratingStar_32 from "./32/rating/star_32.svg"
import _ratingStarActive_32 from "./32/rating/star-active_32.svg"
import _retailBillboard_32 from "./32/retail/billboard_32.svg"
import _retailComsumer_32 from "./32/retail/comsumer_32.svg"
import _retailPaymentHcs_32 from "./32/retail/payment-hcs_32.svg"
import _retailPaymentTravel_32 from "./32/retail/payment-travel_32.svg"
import _retailPaymentWallet_32 from "./32/retail/payment-wallet_32.svg"
import _retailPurchases_32 from "./32/retail/purchases_32.svg"
import _retailPurchasingProgram_32 from "./32/retail/purchasing-program_32.svg"
import _retailSupplier_32 from "./32/retail/supplier_32.svg"
import _retailTradeObjects_32 from "./32/retail/trade-objects_32.svg"
import _retailWriteDowns_32 from "./32/retail/write-downs_32.svg"
import _textAlignCenter_32 from "./32/text/align-center_32.svg"
import _textAlignLeft_32 from "./32/text/align-left_32.svg"
import _textAlignRight_32 from "./32/text/align-right_32.svg"
import _textBold_32 from "./32/text/bold_32.svg"
import _textCopy_32 from "./32/text/copy_32.svg"
import _textItalic_32 from "./32/text/italic_32.svg"
import _textMedium_32 from "./32/text/medium_32.svg"
import _textType_32 from "./32/text/type_32.svg"
import _textWidthAlignment_32 from "./32/text/width-alignment_32.svg"
import _timeCalendar_32 from "./32/time/calendar_32.svg"
import _timeClock_32 from "./32/time/clock_32.svg"
import _timeDeadline_32 from "./32/time/deadline_32.svg"
import _timeInProgress_32 from "./32/time/in-progress_32.svg"
import _timeTimer_32 from "./32/time/timer_32.svg"
import _timeWaiting_32 from "./32/time/waiting_32.svg"
import _transportAirport_32 from "./32/transport/airport_32.svg"
import _transportBus_32 from "./32/transport/bus_32.svg"
import _transportConductor_32 from "./32/transport/conductor_32.svg"
import _transportCrosswalk_32 from "./32/transport/crosswalk_32.svg"
import _transportDescent_32 from "./32/transport/descent_32.svg"
import _transportDriver_32 from "./32/transport/driver_32.svg"
import _transportEmergencyStop_32 from "./32/transport/emergency-stop_32.svg"
import _transportEvacuator_32 from "./32/transport/evacuator_32.svg"
import _transportFootway_32 from "./32/transport/footway_32.svg"
import _transportJeep_32 from "./32/transport/jeep_32.svg"
import _transportMinibus_32 from "./32/transport/minibus_32.svg"
import _transportParkingSpace_32 from "./32/transport/parking-space_32.svg"
import _transportPddControl_32 from "./32/transport/pdd-control_32.svg"
import _transportPublicTransportTrolley_32 from "./32/transport/public-transport-trolley_32.svg"
import _transportRoadBlock_32 from "./32/transport/road-block_32.svg"
import _transportRoadControl_32 from "./32/transport/road-control_32.svg"
import _transportRoadPrevention_32 from "./32/transport/road-prevention_32.svg"
import _transportRoadSing_32 from "./32/transport/road-sing_32.svg"
import _transportRoadWork_32 from "./32/transport/road-work_32.svg"
import _transportRoads_32 from "./32/transport/roads_32.svg"
import _transportRoutes_32 from "./32/transport/routes_32.svg"
import _transportSmartBusStation_32 from "./32/transport/smart-bus-station_32.svg"
import _transportSmartTraffic_32 from "./32/transport/smart-traffic_32.svg"
import _transportTechnic_32 from "./32/transport/technic_32.svg"
import _transportTrafficFlow_32 from "./32/transport/traffic-flow_32.svg"
import _transportTram_32 from "./32/transport/tram_32.svg"
import _transportTransportControl_32 from "./32/transport/transport-control_32.svg"
import _transportWeightControl_32 from "./32/transport/weight-control_32.svg"
import _workActiveCitizen_32 from "./32/work/active-citizen_32.svg"
import _workAttestation_32 from "./32/work/attestation_32.svg"
import _workCitizensPeople_32 from "./32/work/citizens-people_32.svg"
import _workCompany_32 from "./32/work/company_32.svg"
import _workCompetenceResume_32 from "./32/work/competence-resume_32.svg"
import _workComplaint_32 from "./32/work/complaint_32.svg"
import _workContractor_32 from "./32/work/contractor_32.svg"
import _workCustomer_32 from "./32/work/customer_32.svg"
import _workEmployee_32 from "./32/work/employee_32.svg"
import _workEmployment_32 from "./32/work/employment_32.svg"
import _workHrSocial_32 from "./32/work/hr-social_32.svg"
import _workInteraction_32 from "./32/work/interaction_32.svg"
import _workLikeSocial_32 from "./32/work/like-social_32.svg"
import _workOrganization_32 from "./32/work/organization_32.svg"
import _workSupport_32 from "./32/work/support_32.svg"
import _workTeamPeople_32 from "./32/work/team-people_32.svg"
import _actionClose_56 from "./56/action/close_56.svg"
import _actionRestore_56 from "./56/action/restore_56.svg"
import _actionSelected_56 from "./56/action/selected_56.svg"
import _cadastralArea_56 from "./56/cadastral/area_56.svg"
import _cadastralLandLocateBuildings_56 from "./56/cadastral/land-locate-buildings_56.svg"
import _ecologyWater_56 from "./56/ecology/water_56.svg"
import _industryDgh_56 from "./56/industry/dgh_56.svg"
import _industryResourcesSupply_56 from "./56/industry/resources-supply_56.svg"
import _itPhoto_56 from "./56/IT/photo_56.svg"
import _itSocialNet_56 from "./56/IT/social-net_56.svg"
import _mainAdd_56 from "./56/main/add_56.svg"
import _mainAvatar_56 from "./56/main/avatar_56.svg"
import _mainBlock_56 from "./56/main/block_56.svg"
import _mainSearch_56 from "./56/main/search_56.svg"
import _mainWarning_56 from "./56/main/warning_56.svg"
import _placesBuildings_56 from "./56/places/buildings_56.svg"
import _placesThermalStation_56 from "./56/places/thermal-station_56.svg"
import _transportRoadControl_56 from "./56/transport/road-control_56.svg"
import _transportRoadPrevention_56 from "./56/transport/road-prevention_56.svg"
import _workAttestation_56 from "./56/work/attestation_56.svg"
import _workCitizensPeople_56 from "./56/work/citizens-people_56.svg"
import _workHrSocial_56 from "./56/work/hr-social_56.svg"
import _actionSelected_72 from "./72/action/selected_72.svg"
import _arrowArrowDown_8 from "./8/arrow/arrow-down_8.svg"
import _arrowArrowLeft_8 from "./8/arrow/arrow-left_8.svg"
import _arrowArrowRight_8 from "./8/arrow/arrow-right_8.svg"
import _arrowArrowUp_8 from "./8/arrow/arrow-up_8.svg"
import _mapPlacemark from "./other/map/placemark.svg"
import _mapYandexLogo from "./other/map/yandex-logo.svg"

export default { 'arrow/arrow-bold-down_12': { src: _arrowArrowBoldDown_12, width: 12, height: 8, aspect: 1.5 },
'arrow/arrow-bold-right_12': { src: _arrowArrowBoldRight_12, width: 8, height: 12, aspect: 0.6666666666666666 },
'arrow/arrow-bold-up_12': { src: _arrowArrowBoldUp_12, width: 12, height: 8, aspect: 1.5 },
'arrow/arrow-down_12': { src: _arrowArrowDown_12, width: 12, height: 6, aspect: 2 },
'arrow/arrow-left_12': { src: _arrowArrowLeft_12, width: 6, height: 12, aspect: 0.5 },
'arrow/arrow-right_12': { src: _arrowArrowRight_12, width: 6, height: 12, aspect: 0.5 },
'arrow/arrow-up_12': { src: _arrowArrowUp_12, width: 12, height: 6, aspect: 2 },
'action/add-directory_16': { src: _actionAddDirectory_16, width: 16, height: 16, aspect: 1 },
'action/archive_16': { src: _actionArchive_16, width: 16, height: 16, aspect: 1 },
'action/attach_16': { src: _actionAttach_16, width: 16, height: 17, aspect: 0.9411764705882353 },
'action/close_16': { src: _actionClose_16, width: 16, height: 16, aspect: 1 },
'action/dublicate_16': { src: _actionDublicate_16, width: 16, height: 16, aspect: 1 },
'action/exit_16': { src: _actionExit_16, width: 16, height: 16, aspect: 1 },
'action/export_16': { src: _actionExport_16, width: 16, height: 16, aspect: 1 },
'action/favorite-add_16': { src: _actionFavoriteAdd_16, width: 16, height: 16, aspect: 1 },
'action/favorite-add-active_16': { src: _actionFavoriteAddActive_16, width: 16, height: 16, aspect: 1 },
'action/favorites_16': { src: _actionFavorites_16, width: 16, height: 16, aspect: 1 },
'action/favorites-active_16': { src: _actionFavoritesActive_16, width: 16, height: 16, aspect: 1 },
'action/link_16': { src: _actionLink_16, width: 16, height: 16, aspect: 1 },
'action/load_16': { src: _actionLoad_16, width: 16, height: 16, aspect: 1 },
'action/lock_16': { src: _actionLock_16, width: 16, height: 16, aspect: 1 },
'action/move_16': { src: _actionMove_16, width: 16, height: 16, aspect: 1 },
'action/pin_16': { src: _actionPin_16, width: 16, height: 16, aspect: 1 },
'action/print_16': { src: _actionPrint_16, width: 16, height: 16, aspect: 1 },
'action/restore_16': { src: _actionRestore_16, width: 16, height: 16, aspect: 1 },
'action/save_16': { src: _actionSave_16, width: 16, height: 16, aspect: 1 },
'action/selected_16': { src: _actionSelected_16, width: 16, height: 16, aspect: 1 },
'action/share_16': { src: _actionShare_16, width: 16, height: 16, aspect: 1 },
'action/unpin_16': { src: _actionUnpin_16, width: 17, height: 17, aspect: 1 },
'action/update_16': { src: _actionUpdate_16, width: 18, height: 16, aspect: 1.125 },
'appeals/public_16': { src: _appealsPublic_16, width: 16, height: 16, aspect: 1 },
'appeals/roads_16': { src: _appealsRoads_16, width: 16, height: 16, aspect: 1 },
'arrow/arrow-bold-down_16': { src: _arrowArrowBoldDown_16, width: 16, height: 8, aspect: 2 },
'arrow/arrow-bold-right_16': { src: _arrowArrowBoldRight_16, width: 8, height: 16, aspect: 0.5 },
'arrow/arrow-bold-up_16': { src: _arrowArrowBoldUp_16, width: 16, height: 8, aspect: 2 },
'arrow/arrow-down_16': { src: _arrowArrowDown_16, width: 16, height: 6, aspect: 2.6666666666666665 },
'arrow/arrow-left_16': { src: _arrowArrowLeft_16, width: 6, height: 16, aspect: 0.375 },
'arrow/arrow-right_16': { src: _arrowArrowRight_16, width: 6, height: 16, aspect: 0.375 },
'arrow/arrow-up_16': { src: _arrowArrowUp_16, width: 16, height: 6, aspect: 2.6666666666666665 },
'business/achievement_16': { src: _businessAchievement_16, width: 16, height: 16, aspect: 1 },
'business/business-case_16': { src: _businessBusinessCase_16, width: 16, height: 16, aspect: 1 },
'business/chart_16': { src: _businessChart_16, width: 16, height: 16, aspect: 1 },
'business/chart-analytics_16': { src: _businessChartAnalytics_16, width: 16, height: 16, aspect: 1 },
'business/concept_16': { src: _businessConcept_16, width: 16, height: 16, aspect: 1 },
'business/dashboard_16': { src: _businessDashboard_16, width: 16, height: 16, aspect: 1 },
'business/finance-money_16': { src: _businessFinanceMoney_16, width: 16, height: 16, aspect: 1 },
'business/growth_16': { src: _businessGrowth_16, width: 16, height: 16, aspect: 1 },
'business/monitoring_16': { src: _businessMonitoring_16, width: 16, height: 16, aspect: 1 },
'business/progress_16': { src: _businessProgress_16, width: 16, height: 16, aspect: 1 },
'business/regression_16': { src: _businessRegression_16, width: 16, height: 16, aspect: 1 },
'business/sector_16': { src: _businessSector_16, width: 16, height: 16, aspect: 1 },
'business/statistics_16': { src: _businessStatistics_16, width: 16, height: 16, aspect: 1 },
'cadastral/blueprint_16': { src: _cadastralBlueprint_16, width: 16, height: 16, aspect: 1 },
'cadastral/mncpl-control_16': { src: _cadastralMncplControl_16, width: 16, height: 16, aspect: 1 },
'communication/answer_16': { src: _communicationAnswer_16, width: 16, height: 16, aspect: 1 },
'communication/answer-all_16': { src: _communicationAnswerAll_16, width: 16, height: 16, aspect: 1 },
'communication/at_16': { src: _communicationAt_16, width: 16, height: 16, aspect: 1 },
'communication/grid_16': { src: _communicationGrid_16, width: 16, height: 16, aspect: 1 },
'communication/local-phone_16': { src: _communicationLocalPhone_16, width: 16, height: 16, aspect: 1 },
'communication/mail_16': { src: _communicationMail_16, width: 16, height: 16, aspect: 1 },
'communication/message_16': { src: _communicationMessage_16, width: 16, height: 16, aspect: 1 },
'communication/redo_16': { src: _communicationRedo_16, width: 16, height: 16, aspect: 1 },
'communication/reply_16': { src: _communicationReply_16, width: 16, height: 16, aspect: 1 },
'communication/selection_16': { src: _communicationSelection_16, width: 16, height: 16, aspect: 1 },
'communication/send_16': { src: _communicationSend_16, width: 16, height: 16, aspect: 1 },
'communication/telephone_16': { src: _communicationTelephone_16, width: 16, height: 16, aspect: 1 },
'custom/graph_16': { src: _customGraph_16, width: 16, height: 16, aspect: 1 },
'ecology/danger_16': { src: _ecologyDanger_16, width: 16, height: 16, aspect: 1 },
'ecology/ecology_16': { src: _ecologyEcology_16, width: 16, height: 16, aspect: 1 },
'ecology/ecopost_16': { src: _ecologyEcopost_16, width: 16, height: 16, aspect: 1 },
'ecology/greenings_16': { src: _ecologyGreenings_16, width: 16, height: 16, aspect: 1 },
'ecology/lawn_16': { src: _ecologyLawn_16, width: 16, height: 16, aspect: 1 },
'ecology/recycling_16': { src: _ecologyRecycling_16, width: 16, height: 16, aspect: 1 },
'ecology/separate_16': { src: _ecologySeparate_16, width: 16, height: 16, aspect: 1 },
'ecology/tko_16': { src: _ecologyTko_16, width: 16, height: 16, aspect: 1 },
'ecology/tree_16': { src: _ecologyTree_16, width: 16, height: 16, aspect: 1 },
'ecology/water_16': { src: _ecologyWater_16, width: 16, height: 16, aspect: 1 },
'ecology/wind_16': { src: _ecologyWind_16, width: 16, height: 16, aspect: 1 },
'file/agreement_16': { src: _fileAgreement_16, width: 16, height: 16, aspect: 1 },
'file/application_16': { src: _fileApplication_16, width: 16, height: 16, aspect: 1 },
'file/auditor_16': { src: _fileAuditor_16, width: 16, height: 16, aspect: 1 },
'file/errand_16': { src: _fileErrand_16, width: 16, height: 16, aspect: 1 },
'file/excel-file_16': { src: _fileExcelFile_16, width: 16, height: 16, aspect: 1 },
'file/file_16': { src: _fileFile_16, width: 16, height: 16, aspect: 1 },
'file/file-download_16': { src: _fileFileDownload_16, width: 16, height: 16, aspect: 1 },
'file/insert_16': { src: _fileInsert_16, width: 16, height: 16, aspect: 1 },
'file/list_16': { src: _fileList_16, width: 16, height: 16, aspect: 1 },
'file/new-folder_16': { src: _fileNewFolder_16, width: 16, height: 16, aspect: 1 },
'file/official-file_16': { src: _fileOfficialFile_16, width: 16, height: 16, aspect: 1 },
'file/order_16': { src: _fileOrder_16, width: 16, height: 16, aspect: 1 },
'file/pdf-file_16': { src: _filePdfFile_16, width: 16, height: 16, aspect: 1 },
'file/profilactic_16': { src: _fileProfilactic_16, width: 16, height: 16, aspect: 1 },
'file/registry_16': { src: _fileRegistry_16, width: 16, height: 16, aspect: 1 },
'file/signature-file_16': { src: _fileSignatureFile_16, width: 16, height: 16, aspect: 1 },
'file/template_16': { src: _fileTemplate_16, width: 16, height: 16, aspect: 1 },
'file/word-file_16': { src: _fileWordFile_16, width: 16, height: 16, aspect: 1 },
'government/agricultural_16': { src: _governmentAgricultural_16, width: 16, height: 16, aspect: 1 },
'government/art_16': { src: _governmentArt_16, width: 16, height: 16, aspect: 1 },
'government/city-clean_16': { src: _governmentCityClean_16, width: 16, height: 16, aspect: 1 },
'government/construction_16': { src: _governmentConstruction_16, width: 16, height: 16, aspect: 1 },
'government/culture_16': { src: _governmentCulture_16, width: 16, height: 16, aspect: 1 },
'government/education_16': { src: _governmentEducation_16, width: 16, height: 16, aspect: 1 },
'government/emergency_16': { src: _governmentEmergency_16, width: 16, height: 16, aspect: 1 },
'government/food-service_16': { src: _governmentFoodService_16, width: 16, height: 16, aspect: 1 },
'government/foreign_16': { src: _governmentForeign_16, width: 16, height: 16, aspect: 1 },
'government/funeral_16': { src: _governmentFuneral_16, width: 16, height: 16, aspect: 1 },
'government/government_16': { src: _governmentGovernment_16, width: 16, height: 16, aspect: 1 },
'government/ground-work_16': { src: _governmentGroundWork_16, width: 16, height: 16, aspect: 1 },
'government/law_16': { src: _governmentLaw_16, width: 16, height: 16, aspect: 1 },
'government/medicine_16': { src: _governmentMedicine_16, width: 16, height: 16, aspect: 1 },
'government/new_16': { src: _governmentNew_16, width: 16, height: 16, aspect: 1 },
'government/partnership_16': { src: _governmentPartnership_16, width: 16, height: 16, aspect: 1 },
'government/police_16': { src: _governmentPolice_16, width: 16, height: 16, aspect: 1 },
'government/russia-government_16': { src: _governmentRussiaGovernment_16, width: 16, height: 16, aspect: 1 },
'government/security_16': { src: _governmentSecurity_16, width: 16, height: 16, aspect: 1 },
'government/sport_16': { src: _governmentSport_16, width: 16, height: 16, aspect: 1 },
'government/veterinary_16': { src: _governmentVeterinary_16, width: 16, height: 16, aspect: 1 },
'government/voice_16': { src: _governmentVoice_16, width: 16, height: 16, aspect: 1 },
'industry/canalization_16': { src: _industryCanalization_16, width: 16, height: 16, aspect: 1 },
'industry/cold-water_16': { src: _industryColdWater_16, width: 16, height: 16, aspect: 1 },
'industry/dash_16': { src: _industryDash_16, width: 16, height: 16, aspect: 1 },
'industry/electric-power_16': { src: _industryElectricPower_16, width: 16, height: 16, aspect: 1 },
'industry/energy-supply_16': { src: _industryEnergySupply_16, width: 16, height: 16, aspect: 1 },
'industry/extinguisher_16': { src: _industryExtinguisher_16, width: 16, height: 16, aspect: 1 },
'industry/gas-supply_16': { src: _industryGasSupply_16, width: 16, height: 16, aspect: 1 },
'industry/hcs_16': { src: _industryHcs_16, width: 16, height: 16, aspect: 1 },
'industry/heat_16': { src: _industryHeat_16, width: 16, height: 16, aspect: 1 },
'industry/heat-water_16': { src: _industryHeatWater_16, width: 16, height: 16, aspect: 1 },
'industry/hot-water_16': { src: _industryHotWater_16, width: 16, height: 16, aspect: 1 },
'industry/hydrant_16': { src: _industryHydrant_16, width: 16, height: 16, aspect: 1 },
'industry/resources-supply_16': { src: _industryResourcesSupply_16, width: 16, height: 16, aspect: 1 },
'IT/battery_16': { src: _itBattery_16, width: 16, height: 16, aspect: 1 },
'IT/camera-cctv_16': { src: _itCameraCctv_16, width: 16, height: 16, aspect: 1 },
'IT/cloud-storage_16': { src: _itCloudStorage_16, width: 16, height: 16, aspect: 1 },
'IT/data-system_16': { src: _itDataSystem_16, width: 16, height: 16, aspect: 1 },
'IT/human-capital_16': { src: _itHumanCapital_16, width: 16, height: 16, aspect: 1 },
'IT/photo_16': { src: _itPhoto_16, width: 16, height: 16, aspect: 1 },
'IT/social-net_16': { src: _itSocialNet_16, width: 16, height: 16, aspect: 1 },
'IT/tv_16': { src: _itTv_16, width: 16, height: 16, aspect: 1 },
'IT/video-control_16': { src: _itVideoControl_16, width: 16, height: 16, aspect: 1 },
'main/add_16': { src: _mainAdd_16, width: 16, height: 16, aspect: 1 },
'main/avatar_16': { src: _mainAvatar_16, width: 16, height: 16, aspect: 1 },
'main/block_16': { src: _mainBlock_16, width: 16, height: 16, aspect: 1 },
'main/delete_16': { src: _mainDelete_16, width: 16, height: 16, aspect: 1 },
'main/edit_16': { src: _mainEdit_16, width: 16, height: 16, aspect: 1 },
'main/edit-picture_16': { src: _mainEditPicture_16, width: 16, height: 16, aspect: 1 },
'main/eye_16': { src: _mainEye_16, width: 16, height: 16, aspect: 1 },
'main/help_16': { src: _mainHelp_16, width: 16, height: 16, aspect: 1 },
'main/home_16': { src: _mainHome_16, width: 16, height: 16, aspect: 1 },
'main/image_16': { src: _mainImage_16, width: 16, height: 16, aspect: 1 },
'main/info_16': { src: _mainInfo_16, width: 16, height: 16, aspect: 1 },
'main/notifications_16': { src: _mainNotifications_16, width: 16, height: 16, aspect: 1 },
'main/notifications-active_16': { src: _mainNotificationsActive_16, width: 16, height: 16, aspect: 1 },
'main/remove_16': { src: _mainRemove_16, width: 16, height: 16, aspect: 1 },
'main/search_16': { src: _mainSearch_16, width: 16, height: 16, aspect: 1 },
'main/settings_16': { src: _mainSettings_16, width: 16, height: 16, aspect: 1 },
'main/warning_16': { src: _mainWarning_16, width: 16, height: 16, aspect: 1 },
'map/geopoint_16': { src: _mapGeopoint_16, width: 16, height: 16, aspect: 1 },
'map/geoposition_16': { src: _mapGeoposition_16, width: 16, height: 16, aspect: 1 },
'map/layers_16': { src: _mapLayers_16, width: 16, height: 16, aspect: 1 },
'map/layers-active_16': { src: _mapLayersActive_16, width: 16, height: 16, aspect: 1 },
'map/location_16': { src: _mapLocation_16, width: 16, height: 16, aspect: 1 },
'map/route_16': { src: _mapRoute_16, width: 16, height: 16, aspect: 1 },
'menu/border-shift_16': { src: _menuBorderShift_16, width: 16, height: 16, aspect: 1 },
'menu/clean_16': { src: _menuClean_16, width: 16, height: 16, aspect: 1 },
'menu/decrease_16': { src: _menuDecrease_16, width: 16, height: 16, aspect: 1 },
'menu/exit-fullscreen_16': { src: _menuExitFullscreen_16, width: 16, height: 16, aspect: 1 },
'menu/expand_16': { src: _menuExpand_16, width: 16, height: 16, aspect: 1 },
'menu/filter_16': { src: _menuFilter_16, width: 16, height: 16, aspect: 1 },
'menu/fullscreen_16': { src: _menuFullscreen_16, width: 16, height: 16, aspect: 1 },
'menu/icon-view_16': { src: _menuIconView_16, width: 16, height: 16, aspect: 1 },
'menu/increase_16': { src: _menuIncrease_16, width: 16, height: 16, aspect: 1 },
'menu/menu_16': { src: _menuMenu_16, width: 16, height: 16, aspect: 1 },
'menu/menu-context_16': { src: _menuMenuContext_16, width: 16, height: 16, aspect: 1 },
'menu/other_16': { src: _menuOther_16, width: 16, height: 16, aspect: 1 },
'menu/parameters_16': { src: _menuParameters_16, width: 16, height: 16, aspect: 1 },
'menu/sort_16': { src: _menuSort_16, width: 16, height: 16, aspect: 1 },
'menu/switch_16': { src: _menuSwitch_16, width: 16, height: 16, aspect: 1 },
'menu/table_sort_16': { src: _menuTable_sort_16, width: 16, height: 16, aspect: 1 },
'other/couch_16': { src: _otherCouch_16, width: 16, height: 16, aspect: 1 },
'other/etc_16': { src: _otherEtc_16, width: 16, height: 16, aspect: 1 },
'other/hot-meal_16': { src: _otherHotMeal_16, width: 16, height: 16, aspect: 1 },
'other/knowledge_16': { src: _otherKnowledge_16, width: 16, height: 16, aspect: 1 },
'other/snow_16': { src: _otherSnow_16, width: 16, height: 16, aspect: 1 },
'other/vaccine_16': { src: _otherVaccine_16, width: 16, height: 16, aspect: 1 },
'other/virus_16': { src: _otherVirus_16, width: 16, height: 16, aspect: 1 },
'places/buildings_16': { src: _placesBuildings_16, width: 16, height: 16, aspect: 1 },
'places/child-playground_16': { src: _placesChildPlayground_16, width: 16, height: 16, aspect: 1 },
'places/mayor_16': { src: _placesMayor_16, width: 16, height: 16, aspect: 1 },
'places/park_16': { src: _placesPark_16, width: 16, height: 16, aspect: 1 },
'places/plant_16': { src: _placesPlant_16, width: 16, height: 16, aspect: 1 },
'places/public-space_16': { src: _placesPublicSpace_16, width: 16, height: 16, aspect: 1 },
'places/thermal-station_16': { src: _placesThermalStation_16, width: 16, height: 16, aspect: 1 },
'player/forward_16': { src: _playerForward_16, width: 16, height: 16, aspect: 1 },
'player/microphone_16': { src: _playerMicrophone_16, width: 16, height: 16, aspect: 1 },
'player/music_16': { src: _playerMusic_16, width: 16, height: 16, aspect: 1 },
'player/pause-solid_16': { src: _playerPauseSolid_16, width: 16, height: 16, aspect: 1 },
'player/play_16': { src: _playerPlay_16, width: 16, height: 16, aspect: 1 },
'player/play-solid_16': { src: _playerPlaySolid_16, width: 16, height: 16, aspect: 1 },
'player/rewind_16': { src: _playerRewind_16, width: 16, height: 16, aspect: 1 },
'player/sound-on_16': { src: _playerSoundOn_16, width: 17, height: 16, aspect: 1.0625 },
'rating/dislike_16': { src: _ratingDislike_16, width: 16, height: 16, aspect: 1 },
'rating/dislike-active_16': { src: _ratingDislikeActive_16, width: 16, height: 16, aspect: 1 },
'rating/downgrade_16': { src: _ratingDowngrade_16, width: 16, height: 16, aspect: 1 },
'rating/like_16': { src: _ratingLike_16, width: 15, height: 16, aspect: 0.9375 },
'rating/like-active_16': { src: _ratingLikeActive_16, width: 16, height: 16, aspect: 1 },
'rating/raising_16': { src: _ratingRaising_16, width: 16, height: 16, aspect: 1 },
'rating/star_16': { src: _ratingStar_16, width: 16, height: 16, aspect: 1 },
'rating/star-active_16': { src: _ratingStarActive_16, width: 16, height: 16, aspect: 1 },
'retail/alc_16': { src: _retailAlc_16, width: 16, height: 16, aspect: 1 },
'retail/bread_16': { src: _retailBread_16, width: 16, height: 16, aspect: 1 },
'retail/card_16': { src: _retailCard_16, width: 16, height: 16, aspect: 1 },
'retail/category_16': { src: _retailCategory_16, width: 16, height: 16, aspect: 1 },
'retail/comsumer_16': { src: _retailComsumer_16, width: 16, height: 16, aspect: 1 },
'retail/payment-hcs_16': { src: _retailPaymentHcs_16, width: 16, height: 16, aspect: 1 },
'retail/payment-wallet_16': { src: _retailPaymentWallet_16, width: 16, height: 16, aspect: 1 },
'retail/prepare-retail_16': { src: _retailPrepareRetail_16, width: 16, height: 16, aspect: 1 },
'retail/product_16': { src: _retailProduct_16, width: 16, height: 16, aspect: 1 },
'retail/purchases_16': { src: _retailPurchases_16, width: 16, height: 16, aspect: 1 },
'retail/services_16': { src: _retailServices_16, width: 16, height: 16, aspect: 1 },
'retail/shopping-bag_16': { src: _retailShoppingBag_16, width: 16, height: 16, aspect: 1 },
'retail/supplier_16': { src: _retailSupplier_16, width: 16, height: 16, aspect: 1 },
'retail/supplier-search_16': { src: _retailSupplierSearch_16, width: 16, height: 16, aspect: 1 },
'retail/trade-objects_16': { src: _retailTradeObjects_16, width: 16, height: 16, aspect: 1 },
'text/align-center_16': { src: _textAlignCenter_16, width: 16, height: 16, aspect: 1 },
'text/align-left_16': { src: _textAlignLeft_16, width: 16, height: 16, aspect: 1 },
'text/align-right_16': { src: _textAlignRight_16, width: 16, height: 16, aspect: 1 },
'text/bold_16': { src: _textBold_16, width: 16, height: 16, aspect: 1 },
'text/bullet-list_16': { src: _textBulletList_16, width: 16, height: 16, aspect: 1 },
'text/code-paste_16': { src: _textCodePaste_16, width: 16, height: 16, aspect: 1 },
'text/copy_16': { src: _textCopy_16, width: 16, height: 16, aspect: 1 },
'text/cross_16': { src: _textCross_16, width: 16, height: 16, aspect: 1 },
'text/eraser_16': { src: _textEraser_16, width: 16, height: 16, aspect: 1 },
'text/italic_16': { src: _textItalic_16, width: 16, height: 16, aspect: 1 },
'text/line_16': { src: _textLine_16, width: 16, height: 16, aspect: 1 },
'text/medium_16': { src: _textMedium_16, width: 16, height: 16, aspect: 1 },
'text/number-list_16': { src: _textNumberList_16, width: 16, height: 16, aspect: 1 },
'text/quote_16': { src: _textQuote_16, width: 16, height: 16, aspect: 1 },
'text/type_16': { src: _textType_16, width: 16, height: 16, aspect: 1 },
'text/underline_16': { src: _textUnderline_16, width: 16, height: 16, aspect: 1 },
'text/width-alignment_16': { src: _textWidthAlignment_16, width: 16, height: 16, aspect: 1 },
'time/calendar_16': { src: _timeCalendar_16, width: 16, height: 16, aspect: 1 },
'time/clock_16': { src: _timeClock_16, width: 17, height: 17, aspect: 1 },
'time/finish_16': { src: _timeFinish_16, width: 16, height: 16, aspect: 1 },
'time/in-progress_16': { src: _timeInProgress_16, width: 16, height: 16, aspect: 1 },
'time/loader_16': { src: _timeLoader_16, width: 16, height: 16, aspect: 1 },
'time/start_16': { src: _timeStart_16, width: 16, height: 16, aspect: 1 },
'time/timer_16': { src: _timeTimer_16, width: 16, height: 16, aspect: 1 },
'time/timer-finish_16': { src: _timeTimerFinish_16, width: 16, height: 16, aspect: 1 },
'time/timer-start_16': { src: _timeTimerStart_16, width: 16, height: 16, aspect: 1 },
'time/waiting_16': { src: _timeWaiting_16, width: 16, height: 16, aspect: 1 },
'transport/airport_16': { src: _transportAirport_16, width: 16, height: 16, aspect: 1 },
'transport/available-environment_16': { src: _transportAvailableEnvironment_16, width: 17, height: 16, aspect: 1.0625 },
'transport/bus_16': { src: _transportBus_16, width: 16, height: 16, aspect: 1 },
'transport/bus-stop_16': { src: _transportBusStop_16, width: 16, height: 16, aspect: 1 },
'transport/car_16': { src: _transportCar_16, width: 16, height: 16, aspect: 1 },
'transport/conductor_16': { src: _transportConductor_16, width: 16, height: 16, aspect: 1 },
'transport/crosswalk_16': { src: _transportCrosswalk_16, width: 16, height: 16, aspect: 1 },
'transport/descent_16': { src: _transportDescent_16, width: 16, height: 16, aspect: 1 },
'transport/driver_16': { src: _transportDriver_16, width: 16, height: 16, aspect: 1 },
'transport/emergency-stop_16': { src: _transportEmergencyStop_16, width: 16, height: 16, aspect: 1 },
'transport/minibus_16': { src: _transportMinibus_16, width: 16, height: 16, aspect: 1 },
'transport/pit_16': { src: _transportPit_16, width: 16, height: 16, aspect: 1 },
'transport/public-transport-trolley_16': { src: _transportPublicTransportTrolley_16, width: 16, height: 16, aspect: 1 },
'transport/road-block_16': { src: _transportRoadBlock_16, width: 16, height: 16, aspect: 1 },
'transport/road-control_16': { src: _transportRoadControl_16, width: 16, height: 16, aspect: 1 },
'transport/road-sing_16': { src: _transportRoadSing_16, width: 16, height: 16, aspect: 1 },
'transport/road-work_16': { src: _transportRoadWork_16, width: 16, height: 16, aspect: 1 },
'transport/technic_16': { src: _transportTechnic_16, width: 16, height: 16, aspect: 1 },
'transport/traffic-lights_16': { src: _transportTrafficLights_16, width: 16, height: 16, aspect: 1 },
'transport/traffic-lights-with-indicator_16': { src: _transportTrafficLightsWithIndicator_16, width: 16, height: 18, aspect: 0.8888888888888888 },
'transport/tram_16': { src: _transportTram_16, width: 16, height: 16, aspect: 1 },
'transport/walker_16': { src: _transportWalker_16, width: 16, height: 16, aspect: 1 },
'work/active-citizen_16': { src: _workActiveCitizen_16, width: 16, height: 16, aspect: 1 },
'work/advance_16': { src: _workAdvance_16, width: 16, height: 16, aspect: 1 },
'work/button_16': { src: _workButton_16, width: 17, height: 16, aspect: 1.0625 },
'work/competence-resume_16': { src: _workCompetenceResume_16, width: 16, height: 16, aspect: 1 },
'work/complaint_16': { src: _workComplaint_16, width: 16, height: 16, aspect: 1 },
'work/crutches_16': { src: _workCrutches_16, width: 17, height: 16, aspect: 1.0625 },
'work/employee_16': { src: _workEmployee_16, width: 16, height: 16, aspect: 1 },
'work/employment_16': { src: _workEmployment_16, width: 16, height: 16, aspect: 1 },
'work/invalid_16': { src: _workInvalid_16, width: 16, height: 16, aspect: 1 },
'work/no-hearing_16': { src: _workNoHearing_16, width: 17, height: 16, aspect: 1.0625 },
'work/no-vision_16': { src: _workNoVision_16, width: 17, height: 16, aspect: 1.0625 },
'work/support_16': { src: _workSupport_16, width: 16, height: 16, aspect: 1 },
'work/tactile-surface_16': { src: _workTactileSurface_16, width: 17, height: 16, aspect: 1.0625 },
'work/team-people_16': { src: _workTeamPeople_16, width: 16, height: 16, aspect: 1 },
'work/worker_16': { src: _workWorker_16, width: 16, height: 16, aspect: 1 },
'action/add-directory_20': { src: _actionAddDirectory_20, width: 20, height: 20, aspect: 1 },
'action/archive_20': { src: _actionArchive_20, width: 20, height: 20, aspect: 1 },
'action/attach_20': { src: _actionAttach_20, width: 21, height: 21, aspect: 1 },
'action/close_20': { src: _actionClose_20, width: 20, height: 20, aspect: 1 },
'action/exit_20': { src: _actionExit_20, width: 20, height: 20, aspect: 1 },
'action/export_20': { src: _actionExport_20, width: 20, height: 20, aspect: 1 },
'action/favorite-add_20': { src: _actionFavoriteAdd_20, width: 20, height: 20, aspect: 1 },
'action/favorite-add-active_20': { src: _actionFavoriteAddActive_20, width: 20, height: 20, aspect: 1 },
'action/favorites_20': { src: _actionFavorites_20, width: 20, height: 20, aspect: 1 },
'action/favorites-active_20': { src: _actionFavoritesActive_20, width: 20, height: 20, aspect: 1 },
'action/image-edit_20': { src: _actionImageEdit_20, width: 20, height: 20, aspect: 1 },
'action/link_20': { src: _actionLink_20, width: 20, height: 20, aspect: 1 },
'action/load_20': { src: _actionLoad_20, width: 20, height: 20, aspect: 1 },
'action/move_20': { src: _actionMove_20, width: 20, height: 20, aspect: 1 },
'action/pin_20': { src: _actionPin_20, width: 20, height: 20, aspect: 1 },
'action/print_20': { src: _actionPrint_20, width: 20, height: 20, aspect: 1 },
'action/restore_20': { src: _actionRestore_20, width: 20, height: 20, aspect: 1 },
'action/save_20': { src: _actionSave_20, width: 20, height: 20, aspect: 1 },
'action/selected_20': { src: _actionSelected_20, width: 20, height: 20, aspect: 1 },
'action/share_20': { src: _actionShare_20, width: 20, height: 20, aspect: 1 },
'action/unpin_20': { src: _actionUnpin_20, width: 20, height: 20, aspect: 1 },
'action/update_20': { src: _actionUpdate_20, width: 20, height: 20, aspect: 1 },
'arrow/arrow-bold-down_20': { src: _arrowArrowBoldDown_20, width: 20, height: 12, aspect: 1.6666666666666667 },
'arrow/arrow-bold-right_20': { src: _arrowArrowBoldRight_20, width: 12, height: 20, aspect: 0.6 },
'arrow/arrow-bold-up_20': { src: _arrowArrowBoldUp_20, width: 20, height: 12, aspect: 1.6666666666666667 },
'arrow/arrow-down_20': { src: _arrowArrowDown_20, width: 20, height: 8, aspect: 2.5 },
'arrow/arrow-left_20': { src: _arrowArrowLeft_20, width: 8, height: 20, aspect: 0.4 },
'arrow/arrow-right_20': { src: _arrowArrowRight_20, width: 8, height: 20, aspect: 0.4 },
'arrow/arrow-up_20': { src: _arrowArrowUp_20, width: 20, height: 8, aspect: 2.5 },
'business/achievement_20': { src: _businessAchievement_20, width: 20, height: 20, aspect: 1 },
'business/chart-analytics_20': { src: _businessChartAnalytics_20, width: 20, height: 20, aspect: 1 },
'business/monitoring_20': { src: _businessMonitoring_20, width: 20, height: 20, aspect: 1 },
'business/sector_20': { src: _businessSector_20, width: 20, height: 20, aspect: 1 },
'cadastral/mncpl-control_20': { src: _cadastralMncplControl_20, width: 20, height: 20, aspect: 1 },
'communication/answer_20': { src: _communicationAnswer_20, width: 20, height: 20, aspect: 1 },
'communication/mail_20': { src: _communicationMail_20, width: 20, height: 20, aspect: 1 },
'communication/message_20': { src: _communicationMessage_20, width: 20, height: 20, aspect: 1 },
'communication/reply_20': { src: _communicationReply_20, width: 20, height: 20, aspect: 1 },
'communication/selection_20': { src: _communicationSelection_20, width: 20, height: 20, aspect: 1 },
'communication/send_20': { src: _communicationSend_20, width: 20, height: 20, aspect: 1 },
'communication/telephone_20': { src: _communicationTelephone_20, width: 20, height: 20, aspect: 1 },
'ecology/tko_20': { src: _ecologyTko_20, width: 20, height: 20, aspect: 1 },
'file/agreement_20': { src: _fileAgreement_20, width: 20, height: 20, aspect: 1 },
'file/errand_20': { src: _fileErrand_20, width: 20, height: 20, aspect: 1 },
'file/file_20': { src: _fileFile_20, width: 20, height: 20, aspect: 1 },
'file/file-download_20': { src: _fileFileDownload_20, width: 20, height: 20, aspect: 1 },
'file/insert_20': { src: _fileInsert_20, width: 20, height: 20, aspect: 1 },
'file/new-folder_20': { src: _fileNewFolder_20, width: 20, height: 20, aspect: 1 },
'file/pdf-file-download_20': { src: _filePdfFileDownload_20, width: 20, height: 20, aspect: 1 },
'file/profilactic_20': { src: _fileProfilactic_20, width: 20, height: 20, aspect: 1 },
'government/medicine_20': { src: _governmentMedicine_20, width: 20, height: 20, aspect: 1 },
'government/new_20': { src: _governmentNew_20, width: 20, height: 20, aspect: 1 },
'government/partnership_20': { src: _governmentPartnership_20, width: 20, height: 20, aspect: 1 },
'government/police_20': { src: _governmentPolice_20, width: 20, height: 20, aspect: 1 },
'government/security_20': { src: _governmentSecurity_20, width: 20, height: 20, aspect: 1 },
'government/voice_20': { src: _governmentVoice_20, width: 20, height: 21, aspect: 0.9523809523809523 },
'government/vote_20': { src: _governmentVote_20, width: 20, height: 20, aspect: 1 },
'industry/canalization_20': { src: _industryCanalization_20, width: 20, height: 21, aspect: 0.9523809523809523 },
'industry/central-panel_20': { src: _industryCentralPanel_20, width: 20, height: 20, aspect: 1 },
'industry/dash_20': { src: _industryDash_20, width: 20, height: 20, aspect: 1 },
'IT/video-control_20': { src: _itVideoControl_20, width: 20, height: 20, aspect: 1 },
'main/add_20': { src: _mainAdd_20, width: 20, height: 20, aspect: 1 },
'main/avatar_20': { src: _mainAvatar_20, width: 20, height: 20, aspect: 1 },
'main/block_20': { src: _mainBlock_20, width: 20, height: 20, aspect: 1 },
'main/delete_20': { src: _mainDelete_20, width: 20, height: 20, aspect: 1 },
'main/edit_20': { src: _mainEdit_20, width: 20, height: 20, aspect: 1 },
'main/edit-picture_20': { src: _mainEditPicture_20, width: 20, height: 20, aspect: 1 },
'main/eye_20': { src: _mainEye_20, width: 20, height: 20, aspect: 1 },
'main/help_20': { src: _mainHelp_20, width: 20, height: 20, aspect: 1 },
'main/home_20': { src: _mainHome_20, width: 20, height: 20, aspect: 1 },
'main/image_20': { src: _mainImage_20, width: 20, height: 20, aspect: 1 },
'main/info_20': { src: _mainInfo_20, width: 20, height: 20, aspect: 1 },
'main/notifications_20': { src: _mainNotifications_20, width: 20, height: 20, aspect: 1 },
'main/remove_20': { src: _mainRemove_20, width: 20, height: 20, aspect: 1 },
'main/search_20': { src: _mainSearch_20, width: 20, height: 20, aspect: 1 },
'main/settings_20': { src: _mainSettings_20, width: 20, height: 20, aspect: 1 },
'main/warning_20': { src: _mainWarning_20, width: 20, height: 20, aspect: 1 },
'map/geopoint_20': { src: _mapGeopoint_20, width: 20, height: 20, aspect: 1 },
'map/layers_20': { src: _mapLayers_20, width: 20, height: 20, aspect: 1 },
'map/layers-active_20': { src: _mapLayersActive_20, width: 20, height: 20, aspect: 1 },
'map/location_20': { src: _mapLocation_20, width: 21, height: 20, aspect: 1.05 },
'menu/border-shift_20': { src: _menuBorderShift_20, width: 20, height: 20, aspect: 1 },
'menu/clean_20': { src: _menuClean_20, width: 20, height: 20, aspect: 1 },
'menu/exit-fullscreen_20': { src: _menuExitFullscreen_20, width: 20, height: 20, aspect: 1 },
'menu/expand_20': { src: _menuExpand_20, width: 20, height: 20, aspect: 1 },
'menu/filter_20': { src: _menuFilter_20, width: 20, height: 20, aspect: 1 },
'menu/fullscreen_20': { src: _menuFullscreen_20, width: 20, height: 20, aspect: 1 },
'menu/icon-view_20': { src: _menuIconView_20, width: 20, height: 20, aspect: 1 },
'menu/menu_20': { src: _menuMenu_20, width: 20, height: 20, aspect: 1 },
'menu/menu-context_20': { src: _menuMenuContext_20, width: 20, height: 20, aspect: 1 },
'menu/other_20': { src: _menuOther_20, width: 20, height: 20, aspect: 1 },
'menu/parameters_20': { src: _menuParameters_20, width: 20, height: 20, aspect: 1 },
'menu/sort_20': { src: _menuSort_20, width: 20, height: 20, aspect: 1 },
'other/etc_20': { src: _otherEtc_20, width: 20, height: 20, aspect: 1 },
'other/medal_20': { src: _otherMedal_20, width: 20, height: 20, aspect: 1 },
'places/buildings_20': { src: _placesBuildings_20, width: 20, height: 20, aspect: 1 },
'places/mayor_20': { src: _placesMayor_20, width: 20, height: 20, aspect: 1 },
'player/forward_20': { src: _playerForward_20, width: 20, height: 20, aspect: 1 },
'player/microphone_20': { src: _playerMicrophone_20, width: 20, height: 20, aspect: 1 },
'player/music_20': { src: _playerMusic_20, width: 20, height: 20, aspect: 1 },
'player/pause-solid_20': { src: _playerPauseSolid_20, width: 20, height: 20, aspect: 1 },
'player/play_20': { src: _playerPlay_20, width: 20, height: 20, aspect: 1 },
'player/play-solid_20': { src: _playerPlaySolid_20, width: 20, height: 20, aspect: 1 },
'player/rewind_20': { src: _playerRewind_20, width: 20, height: 20, aspect: 1 },
'rating/dislike_20': { src: _ratingDislike_20, width: 20, height: 20, aspect: 1 },
'rating/like_20': { src: _ratingLike_20, width: 20, height: 20, aspect: 1 },
'rating/star_20': { src: _ratingStar_20, width: 20, height: 20, aspect: 1 },
'rating/star-active_20': { src: _ratingStarActive_20, width: 20, height: 20, aspect: 1 },
'retail/shopping-bag_20': { src: _retailShoppingBag_20, width: 20, height: 20, aspect: 1 },
'text/align-center_20': { src: _textAlignCenter_20, width: 20, height: 20, aspect: 1 },
'text/align-left_20': { src: _textAlignLeft_20, width: 20, height: 20, aspect: 1 },
'text/align-right_20': { src: _textAlignRight_20, width: 20, height: 20, aspect: 1 },
'text/bold_20': { src: _textBold_20, width: 20, height: 20, aspect: 1 },
'text/copy_20': { src: _textCopy_20, width: 20, height: 20, aspect: 1 },
'text/italic_20': { src: _textItalic_20, width: 20, height: 20, aspect: 1 },
'text/medium_20': { src: _textMedium_20, width: 20, height: 20, aspect: 1 },
'text/type_20': { src: _textType_20, width: 20, height: 20, aspect: 1 },
'text/width-alignment_20': { src: _textWidthAlignment_20, width: 20, height: 20, aspect: 1 },
'time/calendar_20': { src: _timeCalendar_20, width: 20, height: 20, aspect: 1 },
'time/clock_20': { src: _timeClock_20, width: 20, height: 20, aspect: 1 },
'time/in-progress_20': { src: _timeInProgress_20, width: 20, height: 20, aspect: 1 },
'time/loader_20': { src: _timeLoader_20, width: 20, height: 20, aspect: 1 },
'time/timer_20': { src: _timeTimer_20, width: 20, height: 20, aspect: 1 },
'time/waiting_20': { src: _timeWaiting_20, width: 20, height: 20, aspect: 1 },
'transport/bus_20': { src: _transportBus_20, width: 20, height: 20, aspect: 1 },
'transport/emergency-stop_20': { src: _transportEmergencyStop_20, width: 20, height: 20, aspect: 1 },
'transport/minibus_20': { src: _transportMinibus_20, width: 20, height: 20, aspect: 1 },
'transport/public-transport-trolley_20': { src: _transportPublicTransportTrolley_20, width: 20, height: 20, aspect: 1 },
'transport/road-control_20': { src: _transportRoadControl_20, width: 20, height: 20, aspect: 1 },
'transport/routes_20': { src: _transportRoutes_20, width: 20, height: 20, aspect: 1 },
'transport/technic_20': { src: _transportTechnic_20, width: 20, height: 20, aspect: 1 },
'transport/tram_20': { src: _transportTram_20, width: 20, height: 20, aspect: 1 },
'work/invalid_20': { src: _workInvalid_20, width: 20, height: 20, aspect: 1 },
'work/support_20': { src: _workSupport_20, width: 20, height: 20, aspect: 1 },
'arrow/arrow-bold-down_24': { src: _arrowArrowBoldDown_24, width: 24, height: 12, aspect: 2 },
'arrow/arrow-bold-right_24': { src: _arrowArrowBoldRight_24, width: 12, height: 24, aspect: 0.5 },
'arrow/arrow-bold-up_24': { src: _arrowArrowBoldUp_24, width: 24, height: 12, aspect: 2 },
'action/add-directory_32': { src: _actionAddDirectory_32, width: 32, height: 32, aspect: 1 },
'action/archive_32': { src: _actionArchive_32, width: 32, height: 32, aspect: 1 },
'action/attach_32': { src: _actionAttach_32, width: 32, height: 32, aspect: 1 },
'action/close_32': { src: _actionClose_32, width: 32, height: 32, aspect: 1 },
'action/dash_32': { src: _actionDash_32, width: 32, height: 32, aspect: 1 },
'action/exit_32': { src: _actionExit_32, width: 32, height: 32, aspect: 1 },
'action/export_32': { src: _actionExport_32, width: 32, height: 32, aspect: 1 },
'action/favorite-add_32': { src: _actionFavoriteAdd_32, width: 32, height: 32, aspect: 1 },
'action/favorite-add-active_32': { src: _actionFavoriteAddActive_32, width: 32, height: 32, aspect: 1 },
'action/link_32': { src: _actionLink_32, width: 32, height: 32, aspect: 1 },
'action/load_32': { src: _actionLoad_32, width: 32, height: 32, aspect: 1 },
'action/move_32': { src: _actionMove_32, width: 32, height: 32, aspect: 1 },
'action/restore_32': { src: _actionRestore_32, width: 32, height: 32, aspect: 1 },
'action/selected_32': { src: _actionSelected_32, width: 32, height: 32, aspect: 1 },
'action/update_32': { src: _actionUpdate_32, width: 32, height: 32, aspect: 1 },
'arrow/arrow-bold-down_32': { src: _arrowArrowBoldDown_32, width: 32, height: 14, aspect: 2.2857142857142856 },
'arrow/arrow-bold-right_32': { src: _arrowArrowBoldRight_32, width: 14, height: 32, aspect: 0.4375 },
'arrow/arrow-bold-up_32': { src: _arrowArrowBoldUp_32, width: 32, height: 14, aspect: 2.2857142857142856 },
'arrow/arrow-down_32': { src: _arrowArrowDown_32, width: 32, height: 12, aspect: 2.6666666666666665 },
'arrow/arrow-left_32': { src: _arrowArrowLeft_32, width: 12, height: 32, aspect: 0.375 },
'arrow/arrow-right_32': { src: _arrowArrowRight_32, width: 12, height: 32, aspect: 0.375 },
'arrow/arrow-up_32': { src: _arrowArrowUp_32, width: 32, height: 12, aspect: 2.6666666666666665 },
'business/achievement_32': { src: _businessAchievement_32, width: 32, height: 32, aspect: 1 },
'business/aim_32': { src: _businessAim_32, width: 32, height: 32, aspect: 1 },
'business/business-case_32': { src: _businessBusinessCase_32, width: 32, height: 32, aspect: 1 },
'business/business-support_32': { src: _businessBusinessSupport_32, width: 32, height: 32, aspect: 1 },
'business/complex_32': { src: _businessComplex_32, width: 32, height: 32, aspect: 1 },
'business/concept_32': { src: _businessConcept_32, width: 32, height: 32, aspect: 1 },
'business/concessions_32': { src: _businessConcessions_32, width: 32, height: 32, aspect: 1 },
'business/finance-money_32': { src: _businessFinanceMoney_32, width: 32, height: 32, aspect: 1 },
'business/growth_32': { src: _businessGrowth_32, width: 32, height: 32, aspect: 1 },
'business/investment_32': { src: _businessInvestment_32, width: 32, height: 32, aspect: 1 },
'business/market-share_32': { src: _businessMarketShare_32, width: 32, height: 32, aspect: 1 },
'business/monitoring_32': { src: _businessMonitoring_32, width: 32, height: 32, aspect: 1 },
'business/opportunities_32': { src: _businessOpportunities_32, width: 32, height: 32, aspect: 1 },
'business/problem-question_32': { src: _businessProblemQuestion_32, width: 32, height: 32, aspect: 1 },
'business/quality-effects_32': { src: _businessQualityEffects_32, width: 32, height: 32, aspect: 1 },
'business/quantity-effects_32': { src: _businessQuantityEffects_32, width: 32, height: 32, aspect: 1 },
'business/sell_32': { src: _businessSell_32, width: 32, height: 32, aspect: 1 },
'business/steps_32': { src: _businessSteps_32, width: 32, height: 32, aspect: 1 },
'business/swot-menaces_32': { src: _businessSwotMenaces_32, width: 32, height: 32, aspect: 1 },
'business/swot-strong_32': { src: _businessSwotStrong_32, width: 32, height: 32, aspect: 1 },
'business/swot-weak_32': { src: _businessSwotWeak_32, width: 32, height: 32, aspect: 1 },
'cadastral/area_32': { src: _cadastralArea_32, width: 32, height: 32, aspect: 1 },
'cadastral/cleaning-territory_32': { src: _cadastralCleaningTerritory_32, width: 32, height: 32, aspect: 1 },
'cadastral/land-control_32': { src: _cadastralLandControl_32, width: 32, height: 32, aspect: 1 },
'cadastral/land-locate-buildings_32': { src: _cadastralLandLocateBuildings_32, width: 32, height: 32, aspect: 1 },
'cadastral/plan_32': { src: _cadastralPlan_32, width: 32, height: 32, aspect: 1 },
'cadastral/snow-territory_32': { src: _cadastralSnowTerritory_32, width: 32, height: 32, aspect: 1 },
'cadastral/topography_32': { src: _cadastralTopography_32, width: 32, height: 32, aspect: 1 },
'communication/answer_32': { src: _communicationAnswer_32, width: 32, height: 32, aspect: 1 },
'communication/contacts_32': { src: _communicationContacts_32, width: 32, height: 32, aspect: 1 },
'communication/email_32': { src: _communicationEmail_32, width: 32, height: 32, aspect: 1 },
'communication/friendly-post_32': { src: _communicationFriendlyPost_32, width: 32, height: 32, aspect: 1 },
'communication/mail_32': { src: _communicationMail_32, width: 32, height: 32, aspect: 1 },
'communication/message_32': { src: _communicationMessage_32, width: 32, height: 32, aspect: 1 },
'communication/reply_32': { src: _communicationReply_32, width: 33, height: 32, aspect: 1.03125 },
'communication/selection_32': { src: _communicationSelection_32, width: 32, height: 32, aspect: 1 },
'communication/send_32': { src: _communicationSend_32, width: 32, height: 32, aspect: 1 },
'communication/telephone_32': { src: _communicationTelephone_32, width: 32, height: 32, aspect: 1 },
'ecology/danger_32': { src: _ecologyDanger_32, width: 32, height: 32, aspect: 1 },
'ecology/eco-monitoring_32': { src: _ecologyEcoMonitoring_32, width: 32, height: 32, aspect: 1 },
'ecology/ecology_32': { src: _ecologyEcology_32, width: 32, height: 32, aspect: 1 },
'ecology/ecopost_32': { src: _ecologyEcopost_32, width: 32, height: 32, aspect: 1 },
'ecology/greenings_32': { src: _ecologyGreenings_32, width: 32, height: 32, aspect: 1 },
'ecology/recycling_32': { src: _ecologyRecycling_32, width: 32, height: 32, aspect: 1 },
'ecology/separate_32': { src: _ecologySeparate_32, width: 32, height: 32, aspect: 1 },
'ecology/tko_32': { src: _ecologyTko_32, width: 32, height: 32, aspect: 1 },
'ecology/tree_32': { src: _ecologyTree_32, width: 32, height: 32, aspect: 1 },
'ecology/water_32': { src: _ecologyWater_32, width: 32, height: 32, aspect: 1 },
'ecology/weather-monitoring_32': { src: _ecologyWeatherMonitoring_32, width: 32, height: 32, aspect: 1 },
'file/agreement_32': { src: _fileAgreement_32, width: 32, height: 32, aspect: 1 },
'file/auditor_32': { src: _fileAuditor_32, width: 32, height: 32, aspect: 1 },
'file/errand_32': { src: _fileErrand_32, width: 32, height: 32, aspect: 1 },
'file/excel-file_32': { src: _fileExcelFile_32, width: 32, height: 32, aspect: 1 },
'file/file_32': { src: _fileFile_32, width: 32, height: 32, aspect: 1 },
'file/file-download_32': { src: _fileFileDownload_32, width: 32, height: 32, aspect: 1 },
'file/insert_32': { src: _fileInsert_32, width: 32, height: 32, aspect: 1 },
'file/list_32': { src: _fileList_32, width: 32, height: 32, aspect: 1 },
'file/new-folder_32': { src: _fileNewFolder_32, width: 32, height: 32, aspect: 1 },
'file/official-file_32': { src: _fileOfficialFile_32, width: 32, height: 32, aspect: 1 },
'file/order_32': { src: _fileOrder_32, width: 32, height: 32, aspect: 1 },
'file/pdf-file_32': { src: _filePdfFile_32, width: 32, height: 32, aspect: 1 },
'file/results_32': { src: _fileResults_32, width: 32, height: 32, aspect: 1 },
'file/template_32': { src: _fileTemplate_32, width: 32, height: 32, aspect: 1 },
'file/word-file_32': { src: _fileWordFile_32, width: 32, height: 32, aspect: 1 },
'government/agricultural_32': { src: _governmentAgricultural_32, width: 32, height: 32, aspect: 1 },
'government/art_32': { src: _governmentArt_32, width: 32, height: 33, aspect: 0.9696969696969697 },
'government/city_clean_32': { src: _governmentCity_clean_32, width: 32, height: 32, aspect: 1 },
'government/construction_32': { src: _governmentConstruction_32, width: 32, height: 32, aspect: 1 },
'government/culture_32': { src: _governmentCulture_32, width: 32, height: 32, aspect: 1 },
'government/education_32': { src: _governmentEducation_32, width: 32, height: 32, aspect: 1 },
'government/emergency_32': { src: _governmentEmergency_32, width: 32, height: 32, aspect: 1 },
'government/emergency_56': { src: _governmentEmergency_56, width: 56, height: 56, aspect: 1 },
'government/food-service_32': { src: _governmentFoodService_32, width: 32, height: 32, aspect: 1 },
'government/foreign_32': { src: _governmentForeign_32, width: 32, height: 32, aspect: 1 },
'government/funeral_32': { src: _governmentFuneral_32, width: 32, height: 32, aspect: 1 },
'government/judgment_32': { src: _governmentJudgment_32, width: 32, height: 32, aspect: 1 },
'government/medicine_32': { src: _governmentMedicine_32, width: 32, height: 32, aspect: 1 },
'government/new_32': { src: _governmentNew_32, width: 32, height: 32, aspect: 1 },
'government/partnership_32': { src: _governmentPartnership_32, width: 32, height: 32, aspect: 1 },
'government/police_32': { src: _governmentPolice_32, width: 32, height: 32, aspect: 1 },
'government/restoration_32': { src: _governmentRestoration_32, width: 32, height: 32, aspect: 1 },
'government/russia-government_32': { src: _governmentRussiaGovernment_32, width: 32, height: 32, aspect: 1 },
'government/security_32': { src: _governmentSecurity_32, width: 32, height: 32, aspect: 1 },
'government/sport_32': { src: _governmentSport_32, width: 32, height: 32, aspect: 1 },
'government/tourism_32': { src: _governmentTourism_32, width: 32, height: 32, aspect: 1 },
'government/veterinary_32': { src: _governmentVeterinary_32, width: 32, height: 32, aspect: 1 },
'government/voice_32': { src: _governmentVoice_32, width: 32, height: 32, aspect: 1 },
'government/vote_32': { src: _governmentVote_32, width: 32, height: 32, aspect: 1 },
'industry/cable-system_32': { src: _industryCableSystem_32, width: 32, height: 32, aspect: 1 },
'industry/canalization_32': { src: _industryCanalization_32, width: 32, height: 32, aspect: 1 },
'industry/cold-water_32': { src: _industryColdWater_32, width: 32, height: 32, aspect: 1 },
'industry/conditioner_32': { src: _industryConditioner_32, width: 32, height: 32, aspect: 1 },
'industry/dgh_32': { src: _industryDgh_32, width: 32, height: 32, aspect: 1 },
'industry/electric-power_32': { src: _industryElectricPower_32, width: 32, height: 32, aspect: 1 },
'industry/energy-supply_32': { src: _industryEnergySupply_32, width: 32, height: 32, aspect: 1 },
'industry/equipment-settings_32': { src: _industryEquipmentSettings_32, width: 32, height: 32, aspect: 1 },
'industry/gas-supply_32': { src: _industryGasSupply_32, width: 32, height: 32, aspect: 1 },
'industry/hcs_32': { src: _industryHcs_32, width: 32, height: 32, aspect: 1 },
'industry/heat_32': { src: _industryHeat_32, width: 32, height: 32, aspect: 1 },
'industry/heat-water_32': { src: _industryHeatWater_32, width: 32, height: 32, aspect: 1 },
'industry/hot-water_32': { src: _industryHotWater_32, width: 32, height: 32, aspect: 1 },
'industry/light-system_32': { src: _industryLightSystem_32, width: 32, height: 32, aspect: 1 },
'industry/network-system_32': { src: _industryNetworkSystem_32, width: 32, height: 32, aspect: 1 },
'industry/resources-supply_32': { src: _industryResourcesSupply_32, width: 32, height: 32, aspect: 1 },
'industry/ventilation_32': { src: _industryVentilation_32, width: 32, height: 32, aspect: 1 },
'IT/app_32': { src: _itApp_32, width: 32, height: 32, aspect: 1 },
'IT/big-data_32': { src: _itBigData_32, width: 32, height: 32, aspect: 1 },
'IT/cloud-storage_32': { src: _itCloudStorage_32, width: 32, height: 32, aspect: 1 },
'IT/data-system_32': { src: _itDataSystem_32, width: 32, height: 32, aspect: 1 },
'IT/digital-services_32': { src: _itDigitalServices_32, width: 32, height: 32, aspect: 1 },
'IT/digitalization_32': { src: _itDigitalization_32, width: 32, height: 32, aspect: 1 },
'IT/human-capital_32': { src: _itHumanCapital_32, width: 32, height: 32, aspect: 1 },
'IT/integration_32': { src: _itIntegration_32, width: 32, height: 32, aspect: 1 },
'IT/integration-platform_32': { src: _itIntegrationPlatform_32, width: 32, height: 32, aspect: 1 },
'IT/it-solutions_32': { src: _itItSolutions_32, width: 32, height: 32, aspect: 1 },
'IT/personal-computer_32': { src: _itPersonalComputer_32, width: 32, height: 32, aspect: 1 },
'IT/smart-city_32': { src: _itSmartCity_32, width: 32, height: 32, aspect: 1 },
'IT/social-net_32': { src: _itSocialNet_32, width: 32, height: 32, aspect: 1 },
'IT/system-settings_32': { src: _itSystemSettings_32, width: 32, height: 32, aspect: 1 },
'IT/tv_32': { src: _itTv_32, width: 32, height: 32, aspect: 1 },
'IT/video-control_32': { src: _itVideoControl_32, width: 32, height: 32, aspect: 1 },
'main/add_32': { src: _mainAdd_32, width: 32, height: 32, aspect: 1 },
'main/avatar_32': { src: _mainAvatar_32, width: 32, height: 32, aspect: 1 },
'main/block_32': { src: _mainBlock_32, width: 32, height: 32, aspect: 1 },
'main/delete_32': { src: _mainDelete_32, width: 32, height: 32, aspect: 1 },
'main/edit_32': { src: _mainEdit_32, width: 32, height: 32, aspect: 1 },
'main/eye_32': { src: _mainEye_32, width: 32, height: 32, aspect: 1 },
'main/help_32': { src: _mainHelp_32, width: 32, height: 32, aspect: 1 },
'main/image_32': { src: _mainImage_32, width: 32, height: 32, aspect: 1 },
'main/info_32': { src: _mainInfo_32, width: 32, height: 32, aspect: 1 },
'main/notifications_32': { src: _mainNotifications_32, width: 32, height: 32, aspect: 1 },
'main/remove_32': { src: _mainRemove_32, width: 32, height: 32, aspect: 1 },
'main/search_32': { src: _mainSearch_32, width: 33, height: 32, aspect: 1.03125 },
'main/settings_32': { src: _mainSettings_32, width: 32, height: 32, aspect: 1 },
'main/warning_32': { src: _mainWarning_32, width: 32, height: 32, aspect: 1 },
'map/geolocation-map_32': { src: _mapGeolocationMap_32, width: 32, height: 32, aspect: 1 },
'map/geopoint_32': { src: _mapGeopoint_32, width: 32, height: 32, aspect: 1 },
'map/layers_32': { src: _mapLayers_32, width: 32, height: 32, aspect: 1 },
'map/layers-active_32': { src: _mapLayersActive_32, width: 32, height: 32, aspect: 1 },
'map/location_32': { src: _mapLocation_32, width: 33, height: 33, aspect: 1 },
'map/route_32': { src: _mapRoute_32, width: 32, height: 32, aspect: 1 },
'menu/exit-fullscreen_32': { src: _menuExitFullscreen_32, width: 32, height: 32, aspect: 1 },
'menu/expand_32': { src: _menuExpand_32, width: 32, height: 32, aspect: 1 },
'menu/filter_32': { src: _menuFilter_32, width: 32, height: 32, aspect: 1 },
'menu/fullscreen_32': { src: _menuFullscreen_32, width: 32, height: 32, aspect: 1 },
'menu/menu_32': { src: _menuMenu_32, width: 32, height: 32, aspect: 1 },
'menu/menu-context_32': { src: _menuMenuContext_32, width: 32, height: 32, aspect: 1 },
'menu/other_32': { src: _menuOther_32, width: 33, height: 32, aspect: 1.03125 },
'menu/parameters_32': { src: _menuParameters_32, width: 32, height: 32, aspect: 1 },
'menu/sort_32': { src: _menuSort_32, width: 32, height: 32, aspect: 1 },
'other/ded-moroz_32': { src: _otherDedMoroz_32, width: 32, height: 32, aspect: 1 },
'other/etc_32': { src: _otherEtc_32, width: 32, height: 32, aspect: 1 },
'other/gift_32': { src: _otherGift_32, width: 32, height: 32, aspect: 1 },
'other/hot-meal_32': { src: _otherHotMeal_32, width: 32, height: 32, aspect: 1 },
'other/vaccine_32': { src: _otherVaccine_32, width: 32, height: 32, aspect: 1 },
'other/virus_32': { src: _otherVirus_32, width: 32, height: 32, aspect: 1 },
'other/window_32': { src: _otherWindow_32, width: 32, height: 32, aspect: 1 },
'places/attractions_32': { src: _placesAttractions_32, width: 32, height: 32, aspect: 1 },
'places/buildings_32': { src: _placesBuildings_32, width: 32, height: 32, aspect: 1 },
'places/child-playground_32': { src: _placesChildPlayground_32, width: 32, height: 32, aspect: 1 },
'places/childrens-camp_32': { src: _placesChildrensCamp_32, width: 32, height: 32, aspect: 1 },
'places/culture-objects_32': { src: _placesCultureObjects_32, width: 32, height: 32, aspect: 1 },
'places/hospital_32': { src: _placesHospital_32, width: 32, height: 32, aspect: 1 },
'places/infrastructure_32': { src: _placesInfrastructure_32, width: 32, height: 33, aspect: 0.9696969696969697 },
'places/institution_32': { src: _placesInstitution_32, width: 32, height: 32, aspect: 1 },
'places/lean-smart-city_32': { src: _placesLeanSmartCity_32, width: 32, height: 32, aspect: 1 },
'places/managing_32': { src: _placesManaging_32, width: 32, height: 32, aspect: 1 },
'places/mayor_32': { src: _placesMayor_32, width: 32, height: 32, aspect: 1 },
'places/medical-point_32': { src: _placesMedicalPoint_32, width: 32, height: 32, aspect: 1 },
'places/mountains_32': { src: _placesMountains_32, width: 32, height: 32, aspect: 1 },
'places/park_32': { src: _placesPark_32, width: 32, height: 32, aspect: 1 },
'places/plant_32': { src: _placesPlant_32, width: 32, height: 32, aspect: 1 },
'places/police_32': { src: _placesPolice_32, width: 32, height: 32, aspect: 1 },
'places/public-space_32': { src: _placesPublicSpace_32, width: 32, height: 32, aspect: 1 },
'places/rest-places_32': { src: _placesRestPlaces_32, width: 32, height: 32, aspect: 1 },
'places/sport-objects_32': { src: _placesSportObjects_32, width: 32, height: 32, aspect: 1 },
'places/thermal-station_32': { src: _placesThermalStation_32, width: 32, height: 32, aspect: 1 },
'places/trade-module_32': { src: _placesTradeModule_32, width: 32, height: 32, aspect: 1 },
'places/voiting_32': { src: _placesVoiting_32, width: 32, height: 32, aspect: 1 },
'player/forward_32': { src: _playerForward_32, width: 32, height: 32, aspect: 1 },
'player/microphone_32': { src: _playerMicrophone_32, width: 32, height: 32, aspect: 1 },
'player/music_32': { src: _playerMusic_32, width: 32, height: 32, aspect: 1 },
'player/pause-solid_32': { src: _playerPauseSolid_32, width: 32, height: 32, aspect: 1 },
'player/play_32': { src: _playerPlay_32, width: 32, height: 32, aspect: 1 },
'player/play-solid_32': { src: _playerPlaySolid_32, width: 32, height: 32, aspect: 1 },
'player/rewind_32': { src: _playerRewind_32, width: 32, height: 32, aspect: 1 },
'player/video_32': { src: _playerVideo_32, width: 32, height: 32, aspect: 1 },
'rating/dislike_32': { src: _ratingDislike_32, width: 32, height: 32, aspect: 1 },
'rating/like_32': { src: _ratingLike_32, width: 32, height: 32, aspect: 1 },
'rating/smile-badly_32': { src: _ratingSmileBadly_32, width: 32, height: 32, aspect: 1 },
'rating/smile-great_32': { src: _ratingSmileGreat_32, width: 32, height: 32, aspect: 1 },
'rating/smile-neutral_32': { src: _ratingSmileNeutral_32, width: 32, height: 32, aspect: 1 },
'rating/star_32': { src: _ratingStar_32, width: 32, height: 32, aspect: 1 },
'rating/star-active_32': { src: _ratingStarActive_32, width: 32, height: 32, aspect: 1 },
'retail/billboard_32': { src: _retailBillboard_32, width: 32, height: 32, aspect: 1 },
'retail/comsumer_32': { src: _retailComsumer_32, width: 32, height: 32, aspect: 1 },
'retail/payment-hcs_32': { src: _retailPaymentHcs_32, width: 32, height: 32, aspect: 1 },
'retail/payment-travel_32': { src: _retailPaymentTravel_32, width: 32, height: 32, aspect: 1 },
'retail/payment-wallet_32': { src: _retailPaymentWallet_32, width: 32, height: 32, aspect: 1 },
'retail/purchases_32': { src: _retailPurchases_32, width: 32, height: 32, aspect: 1 },
'retail/purchasing-program_32': { src: _retailPurchasingProgram_32, width: 32, height: 32, aspect: 1 },
'retail/supplier_32': { src: _retailSupplier_32, width: 32, height: 32, aspect: 1 },
'retail/trade-objects_32': { src: _retailTradeObjects_32, width: 32, height: 32, aspect: 1 },
'retail/write-downs_32': { src: _retailWriteDowns_32, width: 32, height: 32, aspect: 1 },
'text/align-center_32': { src: _textAlignCenter_32, width: 32, height: 32, aspect: 1 },
'text/align-left_32': { src: _textAlignLeft_32, width: 32, height: 32, aspect: 1 },
'text/align-right_32': { src: _textAlignRight_32, width: 32, height: 32, aspect: 1 },
'text/bold_32': { src: _textBold_32, width: 32, height: 32, aspect: 1 },
'text/copy_32': { src: _textCopy_32, width: 32, height: 32, aspect: 1 },
'text/italic_32': { src: _textItalic_32, width: 32, height: 32, aspect: 1 },
'text/medium_32': { src: _textMedium_32, width: 32, height: 32, aspect: 1 },
'text/type_32': { src: _textType_32, width: 32, height: 32, aspect: 1 },
'text/width-alignment_32': { src: _textWidthAlignment_32, width: 32, height: 32, aspect: 1 },
'time/calendar_32': { src: _timeCalendar_32, width: 32, height: 32, aspect: 1 },
'time/clock_32': { src: _timeClock_32, width: 32, height: 32, aspect: 1 },
'time/deadline_32': { src: _timeDeadline_32, width: 32, height: 32, aspect: 1 },
'time/in-progress_32': { src: _timeInProgress_32, width: 32, height: 32, aspect: 1 },
'time/timer_32': { src: _timeTimer_32, width: 32, height: 32, aspect: 1 },
'time/waiting_32': { src: _timeWaiting_32, width: 32, height: 32, aspect: 1 },
'transport/airport_32': { src: _transportAirport_32, width: 32, height: 32, aspect: 1 },
'transport/bus_32': { src: _transportBus_32, width: 32, height: 32, aspect: 1 },
'transport/conductor_32': { src: _transportConductor_32, width: 32, height: 32, aspect: 1 },
'transport/crosswalk_32': { src: _transportCrosswalk_32, width: 32, height: 32, aspect: 1 },
'transport/descent_32': { src: _transportDescent_32, width: 32, height: 32, aspect: 1 },
'transport/driver_32': { src: _transportDriver_32, width: 32, height: 32, aspect: 1 },
'transport/emergency-stop_32': { src: _transportEmergencyStop_32, width: 32, height: 32, aspect: 1 },
'transport/evacuator_32': { src: _transportEvacuator_32, width: 33, height: 32, aspect: 1.03125 },
'transport/footway_32': { src: _transportFootway_32, width: 32, height: 32, aspect: 1 },
'transport/jeep_32': { src: _transportJeep_32, width: 32, height: 32, aspect: 1 },
'transport/minibus_32': { src: _transportMinibus_32, width: 32, height: 32, aspect: 1 },
'transport/parking-space_32': { src: _transportParkingSpace_32, width: 32, height: 32, aspect: 1 },
'transport/pdd-control_32': { src: _transportPddControl_32, width: 32, height: 32, aspect: 1 },
'transport/public-transport-trolley_32': { src: _transportPublicTransportTrolley_32, width: 32, height: 33, aspect: 0.9696969696969697 },
'transport/road-block_32': { src: _transportRoadBlock_32, width: 32, height: 32, aspect: 1 },
'transport/road-control_32': { src: _transportRoadControl_32, width: 32, height: 32, aspect: 1 },
'transport/road-prevention_32': { src: _transportRoadPrevention_32, width: 32, height: 32, aspect: 1 },
'transport/road-sing_32': { src: _transportRoadSing_32, width: 32, height: 32, aspect: 1 },
'transport/road-work_32': { src: _transportRoadWork_32, width: 32, height: 32, aspect: 1 },
'transport/roads_32': { src: _transportRoads_32, width: 32, height: 32, aspect: 1 },
'transport/routes_32': { src: _transportRoutes_32, width: 32, height: 32, aspect: 1 },
'transport/smart-bus-station_32': { src: _transportSmartBusStation_32, width: 32, height: 32, aspect: 1 },
'transport/smart-traffic_32': { src: _transportSmartTraffic_32, width: 32, height: 32, aspect: 1 },
'transport/technic_32': { src: _transportTechnic_32, width: 32, height: 32, aspect: 1 },
'transport/traffic-flow_32': { src: _transportTrafficFlow_32, width: 32, height: 32, aspect: 1 },
'transport/tram_32': { src: _transportTram_32, width: 32, height: 32, aspect: 1 },
'transport/transport-control_32': { src: _transportTransportControl_32, width: 32, height: 32, aspect: 1 },
'transport/weight-control_32': { src: _transportWeightControl_32, width: 32, height: 32, aspect: 1 },
'work/active-citizen_32': { src: _workActiveCitizen_32, width: 32, height: 32, aspect: 1 },
'work/attestation_32': { src: _workAttestation_32, width: 32, height: 32, aspect: 1 },
'work/citizens-people_32': { src: _workCitizensPeople_32, width: 32, height: 32, aspect: 1 },
'work/company_32': { src: _workCompany_32, width: 32, height: 32, aspect: 1 },
'work/competence-resume_32': { src: _workCompetenceResume_32, width: 32, height: 32, aspect: 1 },
'work/complaint_32': { src: _workComplaint_32, width: 32, height: 32, aspect: 1 },
'work/contractor_32': { src: _workContractor_32, width: 32, height: 32, aspect: 1 },
'work/customer_32': { src: _workCustomer_32, width: 32, height: 32, aspect: 1 },
'work/employee_32': { src: _workEmployee_32, width: 32, height: 32, aspect: 1 },
'work/employment_32': { src: _workEmployment_32, width: 32, height: 32, aspect: 1 },
'work/hr-social_32': { src: _workHrSocial_32, width: 32, height: 32, aspect: 1 },
'work/interaction_32': { src: _workInteraction_32, width: 32, height: 32, aspect: 1 },
'work/like-social_32': { src: _workLikeSocial_32, width: 32, height: 32, aspect: 1 },
'work/organization_32': { src: _workOrganization_32, width: 32, height: 32, aspect: 1 },
'work/support_32': { src: _workSupport_32, width: 32, height: 32, aspect: 1 },
'work/team-people_32': { src: _workTeamPeople_32, width: 32, height: 32, aspect: 1 },
'action/close_56': { src: _actionClose_56, width: 56, height: 56, aspect: 1 },
'action/restore_56': { src: _actionRestore_56, width: 52, height: 56, aspect: 0.9285714285714286 },
'action/selected_56': { src: _actionSelected_56, width: 56, height: 56, aspect: 1 },
'cadastral/area_56': { src: _cadastralArea_56, width: 56, height: 56, aspect: 1 },
'cadastral/land-locate-buildings_56': { src: _cadastralLandLocateBuildings_56, width: 56, height: 56, aspect: 1 },
'ecology/water_56': { src: _ecologyWater_56, width: 56, height: 56, aspect: 1 },
'industry/dgh_56': { src: _industryDgh_56, width: 56, height: 56, aspect: 1 },
'industry/resources-supply_56': { src: _industryResourcesSupply_56, width: 56, height: 56, aspect: 1 },
'IT/photo_56': { src: _itPhoto_56, width: 56, height: 56, aspect: 1 },
'IT/social-net_56': { src: _itSocialNet_56, width: 56, height: 56, aspect: 1 },
'main/add_56': { src: _mainAdd_56, width: 56, height: 56, aspect: 1 },
'main/avatar_56': { src: _mainAvatar_56, width: 56, height: 56, aspect: 1 },
'main/block_56': { src: _mainBlock_56, width: 56, height: 56, aspect: 1 },
'main/search_56': { src: _mainSearch_56, width: 56, height: 56, aspect: 1 },
'main/warning_56': { src: _mainWarning_56, width: 56, height: 56, aspect: 1 },
'places/buildings_56': { src: _placesBuildings_56, width: 56, height: 56, aspect: 1 },
'places/thermal-station_56': { src: _placesThermalStation_56, width: 56, height: 56, aspect: 1 },
'transport/road-control_56': { src: _transportRoadControl_56, width: 56, height: 56, aspect: 1 },
'transport/road-prevention_56': { src: _transportRoadPrevention_56, width: 56, height: 56, aspect: 1 },
'work/attestation_56': { src: _workAttestation_56, width: 56, height: 56, aspect: 1 },
'work/citizens-people_56': { src: _workCitizensPeople_56, width: 56, height: 56, aspect: 1 },
'work/hr-social_56': { src: _workHrSocial_56, width: 56, height: 56, aspect: 1 },
'action/selected_72': { src: _actionSelected_72, width: 72, height: 72, aspect: 1 },
'arrow/arrow-down_8': { src: _arrowArrowDown_8, width: 8, height: 4, aspect: 2 },
'arrow/arrow-left_8': { src: _arrowArrowLeft_8, width: 4, height: 8, aspect: 0.5 },
'arrow/arrow-right_8': { src: _arrowArrowRight_8, width: 4, height: 8, aspect: 0.5 },
'arrow/arrow-up_8': { src: _arrowArrowUp_8, width: 8, height: 4, aspect: 2 },
'map/placemark': { src: _mapPlacemark, width: 24, height: 37, aspect: 0.6486486486486487 },
'map/yandex-logo': { src: _mapYandexLogo, width: 48, height: 17, aspect: 2.823529411764706 } }